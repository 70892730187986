import React, { useEffect } from 'react';
import Advertistments from './components/Advertistments';
import Footer from './components/Footer';
import GoogleAds from './components/googleAds/GoogleAds';
import MainContent from './components/MainContent';
import Sidebar from './components/Sidebar';
import TopNavbar from './components/TopNavbar';
// import logo from './logo.svg';
// import ReactGA from "react-ga"
// import './App.css';

// const TRACKING_ID = "UA-185897986-1";
// ReactGA.initialize(TRACKING_ID);


function App() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //  }, [])

  return (
  <div className="flex min-h-screen ">
   <div className="mx-auto  flex-1 ">

     <div>
       <div className="flex flex-row w-full  sm:grid-cols-1 md:grid-cols-3 md:gap-1 lg:grid-cols-4 dark:bg-gray-900">
         {/* <div className= "rounded-lg bg-red-500 m-1"><div className="">1</div></div> */}
         <div > <Sidebar/></div>
         <div className= "w-full m-1 sm:grid-cols-1  md:col-start-1 md:col-span-3 lg:grid-cols-3 lg:col-start-2 dark:bg-gray-900">

           <div className= " w-full grid sm:grid-cols-1  md:grid-cols-1 md:gap-1 lg:grid-cols-8 dark:bg-gray-900 bg-slate-50">
              <div className='lg:col-start-3 lg:col-span-6 dark:bg-gray-900 bg-slate-50'>
                <TopNavbar/>
              </div>
            </div>
           <div className= "flex flex-row rounded-lg  m-1 w-full grid sm:grid-cols-1 md:grid-cols-3 md:gap-1 lg:grid-cols-8 xl:grid-cols-13 2xl:grid-cols-12   dark:bg-gray-900">
             <div className= "flex flex-row  rounded-lg min-h-screen m-1 w-full grid sm:grid-cols-1 md:col-span-2 md:gap-1 lg:col-start-3 lg:col-span-4 xl:col-start-3 lg:w-full xl:col-span-4 xl:w-full  2xl:col-start-3 2xl:col-span-7 2xl:w-full  dark:bg-gray-900"><MainContent/></div>
             <div className= "flex flex-row rounded-lg  m-1 w-full lg:col-start-7 lg:col-span-2 xl:col-start-7 xl:col-span-2 mx-auto 2xl:col-start-10 2xl:col-span-3 dark:bg-gray-900"><Advertistments/></div>
           </div>
           <div className= " w-full  grid sm:grid-cols-1  md:grid-cols-1 md:gap-1 lg:grid-cols-8 dark:bg-gray-900 bg-slate-50"><div className='  lg:col-start-3 lg:col-span-6 dark:bg-gray-900  bg-slate-50"'><Footer /> </div></div>
         </div>
       </div>
       
     </div>
    
   </div>
   
  </div>
  );
}

export default App;
