import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const Toggle = ({ on, onChange }) => {
  return (
    <button onClick={onChange}>
      <div className="items-center text-blue-700">
        {on ? (
          <BsToggleOn className="text-xl" />
        ) : (
          <BsToggleOff className="text-xl" />
        )}
      </div>
    </button>
  );
};

export default Toggle;
