import React, { useContext } from 'react'
import {ThemeContext} from './ThemeContext'

import {FaSun, FaMoon} from 'react-icons/fa'
import {FcNoIdea, FcIdea} from 'react-icons/fc'

const Toggle = () => {
  const {theme,setTheme} = useContext(ThemeContext)

  return (
      <div className='transition ease-in-out duration-500 rounded-full p-2'>
          {theme === 'dark' ? (
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAEqUlEQVRoge2ZTUxcVRTHf2f4KAWaDEM1MERLF7bQOtYa0YaamoKYqFWjC5YmLlqGlFRjF7oxJXGhiaZRY5mhxnSPiWxsTPphk6ZIDA2JgkJr0lIiSNIMRTOUAadzXLzha3iP9y7MLEzmn8zinXvP//zP3PvuPfc+yCOPPPJwgkZ5Xbt4LZcxJFfE2k0pyjBQwAP2ynHiuYjjywUpAEonsBN4lEI+zFWYnIyARngcYRAoSpuSwNMS5pdsx8r6CGgPBUA3y+IBClG6tDP78Qo9C4twEeEW8D0LXJATzNt2jHESoXGNXWikiveAz2z5OymkmgMoR4A9Evb28nueQholAoTTj3HgIsJlhD78DEkrD/QM9RQwCJQ40MwjNEgbQ9pDATOEUA6iNAMtQHm6X1TCtGc3gQhvIHzn0BwHBlB2IwRdqKYQRlAaWBacEYw3pZ1eL7o8TyFKuMI8SQefcuCwx7+jCqVqnfYkPn70KstoFdIIfbbzexE+gWAlBAPgL4fSYst+fwFm4jA5DZMxSOl6YfokzHNeNXkfAQDhEjgkUFMJoVoos5n+27Zav0cegtkEDI3BRMwpymUTSWbLmtC31ibwRC0cqLMXn4myEqtvqNZp/K+ZSDJdl38GUqssoR3wWI0hDbCrBvbWZlpTbGHAhMYoAWnjb2B0yVBTuTHxi9hdY70zSwEYkbeZMaEwSkCj7AEetjzFmgabxb5ai8tCtZ7lSRN3TwmoIhrlHWAQ2A5Y/5yXOe+G0hKoTo+CEiBFv0Z5P12SuMI1Ae0mRJRrwOfAlqWGlUO/WQQDK59KgE+IcVW7Cbm5Oiag3ZRqhE6U67Zrf0XZRqTao8JmQxYaUQY1whf6DducXG0T0CivAr8jnAKKbT1L7M0bwlZHrkKEEywwqlHesutgPwI+5lDmsiTPHetuzICgiL0e2wTkGJcIsA94F5i1JU0smEhcH85cSZQvKaJe2vjWroNrLaRfs5MkZxBeWtXwzC6rNMgGxu/CwM1Max9KWNoZXs/VdRWSo9yWdl5GaAXuLTVMTm9Eqj0mV9VFCeADAjzvJh5Mq9Gv2EERP6EE8Qm0PAXlm9wLZhNwYdCqUIVplCaTs7NZKdHBHTS91acUhscM1drg19vL5XWKKdODv1kpcQ4/ULdkmIjBzQkTitW48efqqSjUaxcVJhRm1WiCZ9f4DI9tLIkbE/DbnUyrUECDCY3peWDtjqxYB5T+UYgn3DniCegfsRK3W/+VgyaSzE5kyguOr/1kDKamrcIsGLDKg8Uddm4B7sWtPn9Nux0pm4FTXiV5v5U4h5957mKatDmSCNvTZw9XeJ9CCQ7jLD6evkm45cqjTCFcAcfL3kJSNHmV5T0B4cUVT3GgF6EDH/sJ4JdjNKcvqP5Zh2UeaJE2mgjgx8d+hA6gl5UJrY7lIssjNMoPwCg+zuPnqrRiW8BohKMIZx1oTkqY07Z+PRQzwyFSvALUSTijdHFA1m+nVRGinF9TO0EfUxySzoxLgU0i67fFIig+wsD9FeYkcDzb4iFHHzikjXGUT5cNnM7FtwHI5Reaf/kY+AMYJ8lHOYuTS2iEI7n+yJdHHnn8z/EfVJZKYqvc8UQAAAAASUVORK5CYII="
                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                className='text-gray-500 text-2xl h-6 w-6 dark:text-gray-400 cursor-pointer'
                />


            //   <FcIdea
            //       onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            //       className='text-gray-500 text-2xl dark:text-gray-400 cursor-pointer'
            //   />
          ) : (

            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAExElEQVRoge2XX2hTVxzHPyc3iWn+tal1OMVWxHbbg20d2yqd06ngP2RMGRN80JeVbQq+DJysMPYwpi9DmLDC3BQUJupwE5SpKHNiV/uwWSubtDLFKvNf2jSpTW6ae+/ZQ+y0pjfee5Mqg3xeQu7v/Pl9z/md3/kdKFGiRIlnichn3LjgxAxN03cIxFIg9JR8GmUIxGmXkFvb2lf0mDUyFbBxwYkZumZ0AZUT4p51Yorb1fD12WU3xjO6zHppmr6DZ+88QMTQjS/NjKYCHoSNbcon65RX6k66miIly8xspgJwGPM1tRmq6zJOuuYjbGbIJ8ASQkDjfJXJU7OrXl2XoeaBALdHUlefRnHLQqcxxV3oAFJCzwUvm7cNoKYE/qBBJp39faExzZ7tEXQtb7IriIJ3ACA17GLnJ5WEIzo1dRlmzxmhfl6atk8ruXnVk9O+mDtSFAEA/qDBJN/D/2pKkLw//vDllTqr30tQUWUUPG9RBDxfrbHx8xh//+nl2mUvV//ycueGm83b+wmEcp0cuOvm9199tLbd5eUFakFzF3wGFLckVKHzxYdVaBnBqvVDABzdG6JqqkZdQ5qu38qQj+nou+Ll/Ck/La0DHIiEOXMk6Gj+gndA1wS93ZPQMtmD2tfroa83G/fR224unMt1fpRj+0KkVcHaTQnmzk85mt+2gCnTdCqf00zt1694uH4l9+COh5oUXOzwIQRs2DLo6EzYFlBbn6a2fgQA4crm+keJ9yvE+xXL4104VwaAr0yypiVu1x1rZ8AfkKxaP0R8wMXsOWl0TTBlmo4/IPnhm8KK1Dt9D114ZaHK4V0Gg1Hr62qpZXJYcPxAkHlLk8xpStP4uspLc1V+3B3G0Au7pGLRh7vlUiSvLUra6m9ZqpoUqI/k9UAY/IHiFm3Af+FpFUshpLgl77yf4FqPh2RSkFZd9HZ5WfrufX7+PsT9hPNk9njlOn2WeYIYD0sChID9X5UjJTQvT6Jr0HnKb2siM6bNHFu5BsP2dtWSgNEcD9B7cRK6vUXKS0Pz2JvYsJlJbd/E0VvWU+ST8Ack9Y8JiA/YG79oxZwTlq8bwh8Ye4/c++d/ImDmiyMsens45/ulTt84rc1xJEBxy7zlxJOITNH54LMYHu/Y1Td0QXfHUxCga4KFbyWprrWXsyG78h/vjFIxOTfbtB8vI3bPXgg5Lqd/+SlIa9tdzp/yc2xfCDWZ/0b2ByTL1w2xePVwTv0E2Yvy6F77ZYljAYNRF/t3VtDSOsAbK4e52OGj65yP230eYvcUJJJIlcHUGo2GZpWG5lTOgR1FGrB7W8R2BipIAMAfZ30ciIRZuylB05IUTUvs1/TSgINt5XSftxf7oxT8IjtzJEi8X2HDlkF8ZfYe62pS8N22CJccOg9FEADZmv7qZS9rWhK8+qaKS8kvxNAF7cfLOLo35ChsHqUoAiD7kNmzPcLhXTpNi1PU1o8wfZZGMKwjpSAWdRG9pXCp00d3h892tjGjaAJGifcrnDwU5OShYo88PvnugaGn44IlTN+aeQSI0xPhiSMkJ8xMpgJcQm4FYhPikD36dUN+ZGY0FdDWvqJHcbsahOAQkJgQ1/KTQHJQ12Xjt50rbz6D+UuUKFHCAv8Ctk+dBd3v7ykAAAAASUVORK5CYII="
                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                className='text-gray-500 text-2xl h-6 w-6 dark:text-gray-400 cursor-pointer'
            />
            //   <FcNoIdea
            //       onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            //       className='text-gray-500 text-2xl dark:text-gray-400 cursor-pointer'
            //   />
          )}
      </div>
  )
}

export default Toggle