import React from "react";
import { useState, forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import {
  useLazyGetReportDataQuery,
  useGetReportDataQuery,
} from "../services/sinduApi";
import ReportTile from "../components/ReportTile";

const Reports = () => {
  const [startMonth, setStartMonth] = useState(new Date());
  const [listData, setListData] = useState([]);
  const [filterMonth, setFilterMonth] = useState(
    new Date(new Date().setDate(new Date().getDate())).getMonth() + 1
  );
  const [station, setStation] = useState("");
  const [artist, setArtist] = useState("");
  const [artistListState, setArtistListState] = useState({});
  const [filtermonthDisplay, setFiltermonthDisplay] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  // const [filterMonth, setFilterMonth] = useState(
  //   new Date(new Date().setDate(new Date().getDate())).getMonth() + 1
  // );
  const [filterYear, setFilterYear] = useState(
    new Date(new Date().setDate(new Date().getDate())).getFullYear()
  );
  var getArtistsfromLocal = JSON.parse(localStorage.getItem("artistArray"));
  // console.log("get value from local", getArtistsfromLocal);
  // console.log("get value from local", getArtistsfromLocal[2]);

  const [getReportDataQuery, { data: reportData, isError, isFetching }] =
    useLazyGetReportDataQuery();

  // console.log(filterMonth);
  // console.log(filterYear);
  // console.log(station);
  // console.log(artist);
  // console.log(reportData);

  const fetchData = async () => {
    const response = await getReportDataQuery({
      filterArtist: artist,
      filtermonth: filterMonth,
      filterYear: filterYear,
      radioStation: station,
    });
    // console.log(artist);
    // console.log(station);
    // console.log(response.data.success);
    setListData(response.data.data);
    console.log(listData);
  };
  const keyValue = (input) =>
    Object.entries(input).forEach(([key, value]) => {
      console.log(key, value);
      return key;
    });

  useEffect(() => {
    // console.log(
    //   "--------------------------",
    //   artist,
    //   filterYear,
    //   filterMonth,
    //   station
    // );
    fetchData();
  }, [artist, filterMonth, filterYear, station]);

  // const artistList = useSelector((state) => state.report.artistArray);
  // console.log(artistList);
  // var artistObj;

  // useEffect(() => {
  //   getListandParse(getArtistsfromLocal);
  // }, []);
  // useEffect(() => {
  //   getListandParse(getArtistsfromLocal);
  // }, [getArtistsfromLocal]);
  // useEffect(() => {
  //   getListandParse(artistList);
  // }, [artistList]);

  // const getListandParse =  (artistList) => {
  //   console.log(artistList);
  //   if (artistList.length > 0) {
  //     artistObj = JSON.parse(artistList);
  //     console.log(artistObj);
  //     setArtistListState(artistObj);

  //   }
  // };

  const artistListDemo = ["artist 1", "artist 2", "artist 3"];

  const MonthFilterInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="dark:bg-slate-800 dark:text-zinc-500 rounded p-2  text-xs border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200 truncate overflow-hidden"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));
  return (
    <div style={{ paddingTop: "3%" }}>
      <div>
        <h2 class="text-xl font-semibold leading-tight dark:text-white text-center md:text-left">
          Reports
        </h2>
      </div>
      <div class="inline-flex mt-2 xs:mt-0 "></div>
      <div style={{ display: "flex" }}>
        <div className=" rounded-lg">
          <p className="text-xs text-slate-300 dark:text-slate-600 text-center truncate overflow-hidden ">
            Filter Artists
          </p>
          <div class="relative sm:mx-1 ">
            <select
              class="appearance-none h-full rounded  block text-xs w-full py-2 px-4 pr-8 leading-tight focus:outline-none  dark:bg-slate-800 dark:text-zinc-500  border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200 "
              defaultValue={20}
              onChange={(e) => setArtist(e.target.value)}
            >
              {/* {artistListDemo &&
              artistListDemo.map((index) => <option value={index}>{index}</option>)} */}
              {getArtistsfromLocal
                ? getArtistsfromLocal.map((index) => (
                    <option value={`${index}`}>{index}</option>
                  ))
                : ""}
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 rounded">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div className=" rounded-lg ">
          <p className="text-xs text-slate-300 dark:text-slate-600 text-start sm:mx-1 md:mx-2 truncate overflow-hidden">
            Filter by Month
          </p>
          <div className="items-start  sm:mx-1 ">
            <DatePicker
              // defaultValue={startMonth}
              selected={startMonth}
              onChange={(date) => {
                const monthName = date.toLocaleString("default", {
                  month: "long",
                });
                console.log(date);
                setFiltermonthDisplay(monthName);
                setStartMonth(date);
                var d = date,
                  month = "" + (d.getMonth() + 1),
                  day = "" + d.getDate(),
                  year = d.getFullYear();

                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;

                setFilterYear(year);
                setFilterMonth(month);
                // setFilterMonth(month);
                // setFilterDay("");
              }}
              dateFormat="MMMM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              customInput={<MonthFilterInput />}
              maxDate={new Date()}
              minDate={new Date("2022-02-28")}
            />
          </div>
        </div>

        <div className=" rounded-lg">
          <p className="text-xs text-slate-300 dark:text-slate-600 text-center truncate overflow-hidden ">
            Filter Fm Channel
          </p>
          <div class="relative sm:mx-1 ">
            <select
              class="appearance-none h-full rounded  block text-xs w-full py-2 px-4 pr-8 leading-tight focus:outline-none  dark:bg-slate-800 dark:text-zinc-500  border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200 "
              defaultValue="Siyatha FM"
              onChange={(e) => {
                setStation(e.target.value);
                console.log(e.target.value);
              }}
            >
              <option value="171037">Hiru FM</option>
              <option value="194578">Sooriyan FM</option>
              <option value="194579">Shakthi FM</option>
              <option value="194580">Shaa FM</option>
              <option value="194582">Siyatha FM</option>
              <option value="194590">Varnam FM</option>
              {/* <option value={20}>20</option> */}
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 rounded">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
        <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
          {/* <DataFetchingError />
          <NoDataError /> */}
          {listData && listData.length != 0 && (
            <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
              <span className="text-gray-500 flex">
                {" "}
                {filterYear}{" "}
                {filtermonthDisplay == "" ? null : (
                  <p className="whitespace-pre"> {filtermonthDisplay} </p>
                )}
                {/* {filterDay == "" ? null : <p className=""> {filterDay} </p>}{" "} */}
                <p className="whitespace-pre">
                  {" "}
                  {/* Top {listLimit} Sinhala Music Chart */}
                </p>
              </span>
            </p>
          )}
        </div>

        <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
          {listData.length > 0 ? (
            listData.map((sindu) => {
              return <ReportTile sindu={sindu} />;
            })
          ) : (
            <div className="items-center w-full">
              <div
                class="bg-teal-50 border-t-4 border-teal-300 dark:border-teal-800 rounded-b text-slate-500 px-4 py-3 shadow-md dark:bg-teal-900 dark:bg-opacity-20 dark:text-teal-600 "
                role="alert"
              >
                <div class="flex">
                  <div class="py-1">
                    <svg
                      class="fill-current h-6 w-6 text-teal-300 dark:text-teal-800 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p class="font-bold">No Data to display</p>
                    <p class="text-sm">Please set values for all filters.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {listData &&
            listData.map((sindu) => {
              return <ReportTile sindu={sindu} />;
            })} */}
        </div>
      </div>
    </div>
  );
};

export default Reports;
