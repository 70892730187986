import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../services/Account";
import { GiHamburgerMenu } from "react-icons/gi";
import { Disclosure } from "@headlessui/react";
import {
  FcHome,
  FcAbout,
  FcNews,
  FcFile,
  FcRating,
  FcMusic,
  FcFilm,
  FcLike,
  FcTrademark,
  FcVoicePresentation,
  FcDoughnutChart,
  FcAcceptDatabase,
} from "react-icons/fc";
import Autocomplete from "./Autocomplete";
import logo from "../assets/logo/CHART.png";

import Toggle from "./theme/ThemeToggle";
import { FcBookmark, FcAssistant } from "react-icons/fc";
import nameTag from "../assets/logo/SinduChart.png";
import darkLogo from "../assets/logo/darktheme.png";
import lightLogo from "../assets/logo/lighttheme.png";

// import sindulogo from '../assets/channels4_profile.jpg'
// import { CgProfile } from "react-icons/cg";
// import { FaRegComments } from "react-icons/fa";
// import { BiMessageSquareDots } from "react-icons/bi";
// import { useState, useEffect } from "react";
// import { Fragment } from "react";
// import { Menu, Transition } from "@headlessui/react"

function Sidebar() {
  const [selectedOption, setSelectedOption] = useState("");
  const [sidebarPanel, setSidebarPanel] = useState(false);
  const [authStatus, setAuthStatus] = useState(false);
  const { getSession } = useContext(AccountContext);
  console.log("auth status", authStatus);
  useEffect(() => {
    getSession().then((session) => {
      console.log("Session:", session);
      setAuthStatus(true);
    });
  }, [authStatus]);
  useEffect(() => {
   
  }, [authStatus]);
  let navigate = useNavigate();

  function classNames(...classes) {
    console.log(classes);
    return classes.filter(Boolean).join(" ");
  }

  function emptyCache() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  }

  const toggle = () => {
    // 👇️ passed function to setState
    setSidebarPanel((current) => !current);
  };

  useEffect(() => {
    console.log(sidebarPanel);
  }, [sidebarPanel]);

  return (
    <div>
      <div as="nav">
        <div
          onClick={toggle}
          className="absolute top-4 right-4 inline-flex items-center peer justify-center rounded-md p-2 text-gray-800 hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white group"
        >
          <FcAcceptDatabase
            className="block lg:hidden h-6 w-6 z-50"
            aria-hidden="true"
          />
        </div>

        {sidebarPanel == true && (
          <>
            {/* Code block starts */}
            <div
              id="popover"
              className="w-7/12 transition duration-150 ease-in-out  absolute mt-20 left-0 ml-20 z-50 sm:w-1/2 md:w-1/3 lg:hidden "
            >
              <div className="w-full  rounded shadow-2xl">
                <div className="w-full h-full px-4 xl:px-8 pt-3 pb-5 rounded-xl bg-white dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 ">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className=" rounded shadow">
                        <img src={logo} className="h-20 w-15" />{" "}
                      </div>
                      <div>
                        {/* <h3 className="mb-2 sm:mb-1 text-gray-800 text-base font-normal leading-4">Andres Berlin</h3>
                                <p className="text-gray-600 text-xs leading-3">Manager Sales</p> */}
                      </div>
                    </div>
                    <div className="relative font-normal text-sm flex items-center text-gray-600"></div>
                  </div>
                  <div className="py-6 flex justify-between items-center">
                    <div className="px-3 w-full">
                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcHome className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            Home
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcLike className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            Sinhala Airplay Charts
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/tamil-songs-chart`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcMusic className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            Tamil Airplay Charts
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/top-albums`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcFilm className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            Top Albums
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/top-artists`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcVoicePresentation className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            Top Artists
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/top-songs-in-channels`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcTrademark className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            Station Airplay Charts
                          </h3>
                        </div>
                      </div>
                      {authStatus ? (
                        <div
                          className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                          onClick={() => {
                            navigate(`/reports`);
                            window.scrollTo(0, 0);
                            setSidebarPanel(false);
                          }}
                        >
                          <div>
                            <FcFile className="text-lg text-gray-600 group-hover:text-white " />
                          </div>
                          <div>
                            <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                              {" "}
                              Reports
                            </h3>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/news`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcNews className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            News
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex items-center shadow-lg rounded-md m-2 p-2 hover:bg-gray-900  group cursor-pointer hover:shadow-lg dark:bg-slate-900 "
                        onClick={() => {
                          navigate(`/about`);
                          window.scrollTo(0, 0);
                          setSidebarPanel(false);
                        }}
                      >
                        <div>
                          <FcAbout className="text-lg text-gray-600 group-hover:text-white " />
                        </div>
                        <div>
                          <h3 className=" px-3 text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                            {" "}
                            About
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="p-6 w-1/2 h-screen bg-slate-50 z-20 fixed top-0 -left-96 lg:left-0 lg:w-64 md:w-48 peer-focus:left-0 peer:transition ease-out delay-150 duration-200  dark:bg-gray-800 ">
          <div className="mx-auto flex flex-col justify-start item-center">
            <div className="mb-6 md:mb-0">
              <div className="flex items-center ">
                <img src={logo} className="h-20 w-15" />
              </div>
            </div>
            <div className=" my-4 border-b border-gray-100 pb-4">
              <div className="flex  mb-1  gap-3  rounded-md group cursor-pointer  m-auto">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                  <Autocomplete
                    options={[
                      "tamil-songs-chart",
                      "top-albums",
                      "top-artists",
                      "top-songs-in-channels",
                    ]}
                    value={selectedOption}
                    onChange={(e) => {
                      setSelectedOption(e);
                      window.scrollTo(0, 0);
                    }}
                  />
                </span>
              </div>

              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/`);
                  window.scrollTo(0, 0);
                  emptyCache();
                }}
              >
                <FcHome className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Home
                </h3>
              </div>

              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcLike className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Sinhala Airplay Charts
                </h3>
              </div>
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/tamil-songs-chart`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcMusic className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Tamil Airplay Charts
                </h3>
              </div>
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/top-albums`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcFilm className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Top Albums
                </h3>
              </div>
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/top-artists`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcVoicePresentation className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Top Artists
                </h3>
              </div>
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/top-songs-in-channels`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcTrademark className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Station Airplay Charts
                </h3>
              </div>
              {authStatus?
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/reports`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcFile className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  Reports
                </h3>
              </div>
:""}
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto"
                onClick={() => {
                  navigate(`/news`);
                  window.scrollTo(0, 0);
                }}
              >
                <FcNews className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  News
                </h3>
              </div>
              <div
                className="flex  mb-1 justify-start items-center gap-3 pl-5 hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto "
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`/about`);
                }}
              >
                <FcAbout className="text-lg text-gray-600 group-hover:text-white " />
                <h3 className="text-xs text-gray-800 dark:text-amber-50 group-hover:text-white font-semibold ">
                  About
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
