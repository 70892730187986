import React,{ createContext } from 'react'
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import userPool from "../components/UserPool";

const AccountContext = createContext();

const Account = (props) => {
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject()
                    } else {
                        resolve(session)
                    }
                })
            } else {
                reject();
            }
        })
    }
    const logout = () => {
        const user = userPool.getCurrentUser();
        if (user) {
            console.log("user details",user)
            user.signOut();
        }
    }

    return (
        <AccountContext.Provider value={ {getSession, logout} } >
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext };