import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const AdBannerMain = () => {

    const [adOneTextOne, setAdOneTextOne] = useState("Sindu Chart")
    const [adOneTextTwo, setAdOneTextTwo] = useState('All your trendinding lists')
    const [adOneTextThree, setAdOneTextThree] = useState('https://www.s3.sinduchart.com')

    const [adTwoTextOne, setAdTwoTextOne] = useState("Sindu Chart")
    const [adTwoTextTwo, setAdTwoTextTwo] = useState('All your trendinding lists')
    const [adTwoTextThree, setAdTwoTextThree] = useState('https://www.s3.sinduchart.com')

    useEffect(() => {
        fetch("https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdOneText.json")
            .then(response => 
                response.json()
                // console.log(response)
            )
            .then((jsonData) => {
                // jsonData is parsed json object received from url
                // console.log(jsonData)
                // console.log(jsonData.text1)
                setAdOneTextOne(jsonData.text1)
                setAdOneTextTwo(jsonData.text2)
                setAdOneTextThree(jsonData.adVertistmentLink)
            })
            .catch((error) => {
                // handle your errors here
                console.error(error)
            })
        fetch("https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdTwoText.json")
            .then(response => 
                response.json()
                // console.log(response)
            )
            .then((jsonData) => {
                // jsonData is parsed json object received from url
                // console.log(jsonData)
                // console.log(jsonData.text1)
                setAdTwoTextOne(jsonData.text1)
                setAdTwoTextTwo(jsonData.text2)
                setAdTwoTextThree(jsonData.adVertistmentLink)
            })
            .catch((error) => {
                // handle your errors here
                console.error(error)
            })
      }, [adOneTextOne,adOneTextTwo]);

     

  return (
    <div className="container mx-auto py-9 md:py-12 px-4 md:px-6  dark:bg-gray-900">
        <div className="flex items-strech justify-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8">

         

            {/* <div className="flex flex-col md:flex-row items-strech justify-between bg-gray-50 py-2 px-2 md:py-2 lg:px-2 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12 "> */}
                {/* py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12 */}
                
                <div className="md:w-2/3 mt-8 md:mt-0 flex-col justify-center md:justify-end">
                    <a  href={adOneTextThree}
                            target="_blank"
                            rel="noreferrer"
                             >
                        <img src="https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdOne.png" alt="" />
                        {/* <img src="https://i.ibb.co/J2BtZdg/Rectangle-56-1.png" alt="" /> */}
                        <div className="mb-1 text-slate-700 dark:text-slate-300 uppercase font-medium text-sm flex items-center justify-center">{adOneTextOne}</div>
                        <p className="text-gray-500 mb-6 flex items-center justify-center">
                            <small>{adOneTextTwo} </small>
                        </p>
                    </a>
                    {/* <h1 className="text-3xl lg:text-xl font-semibold text-gray-800">{adOneTextOne}</h1>
                    <p className="text-base lg:text-base text-gray-800 mt-2">
                    {adOneTextTwo}
                        <span className="font-bold">50%</span>
                    </p> */}
                </div>
                {/* <div className="flex flex-col justify-center md:w-1/3 ">
                </div>

                
            </div> */}

            {/* <div className="flex flex-col md:flex-row  bg-gray-50 py-2 px-2 md:py-2 lg:px-2 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12"> */}
                {/* py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12 */}
                
                <div className="md:w-2/3 mt-8 md:mt-0  flex-col ">
                    <a  href={adTwoTextThree}
                                target="_blank"
                                rel="noreferrer"
                                >
                        <img className='' src="https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdTwo.png" alt="" />
                        {/* <img src="https://i.ibb.co/J2BtZdg/Rectangle-56-1.png" alt="" /> */}
                        {/* <h1 className="text-3xl lg:text-xl font-semibold text-gray-800">{adTwoTextOne}</h1> */}
                        <div className="mb-1 text-slate-700 dark:text-slate-300 uppercase font-medium text-sm flex items-center justify-center">{adTwoTextOne}</div>
                        <p className="text-gray-500 mb-6 flex items-center justify-center">
                            <small>{adTwoTextTwo} </small>
                        </p>
                    </a>
                    
                </div>
                {/* <div className="flex flex-col justify-center md:w-1/3 ">
                    
                </div>
            </div> */}


            
        </div>
    </div>
);
}

export default AdBannerMain

// ad 2 css
// 

           // <div className="flex flex-col justify-center relative md:w-4/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 bg-gray-50 py-6 px-6 md:py-0 md:px-4 lg:px-6  ">
                {/* md:w-4/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 bg-gray-50 py-6 px-6 md:py-0 md:px-4 lg:px-6  */}
           //     <div className="flex flex-col justify-center">
            //        <h1 className="text-3xl lg:text-4xl font-semibold text-gray-800">{adTwoTextOne}</h1>
            //        <p className="text-base lg:text-xl text-gray-800">
             //           {adTwoTextTwo}
             //           {/* <span className="font-bold">30%</span> */}
            //        </p>
             //   </div>
             //   <div className="flex justify-end md:absolute md:bottom-4 md:right-4 lg:bottom-0 lg:right-0">
                    {/* <img src="https://i.ibb.co/rGfP7mp/Rectangle-59-1.png" alt="" className="md:w-20 md:h-20 lg:w-full lg:h-full" /> */}
             //       <img src="https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdTwo.png" alt="" className="md:w-20 md:h-20 lg:w-full lg:h-full" />
             //   </div>
           // </div>

        //    <div className="flex flex-col md:flex-row items-strech justify-between bg-gray-50 py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12 ">
        //         py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12
        //         <div className="flex flex-col justify-center md:w-1/2 ">
        //             <h1 className="text-3xl lg:text-4xl font-semibold text-gray-800">{adOneTextOne}</h1>
        //             <p className="text-base lg:text-xl text-gray-800 mt-2">
        //                 {adOneTextTwo} 
        //                 {/* <span className="font-bold">50%</span> */}
        //             </p>
        //         </div>
        //         <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
        //             <img src="https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdOne.png" alt="" />
        //             <img src="https://i.ibb.co/J2BtZdg/Rectangle-56-1.png" alt="" />
        //         </div>
        //     </div>

    //     <div className="flex flex-col md:flex-row items-strech justify-between bg-gray-50 py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12 ">
    //     py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12
    //     <div className="flex flex-col justify-center md:w-1/2 ">
    //         <h1 className="text-3xl lg:text-4xl font-semibold text-gray-800">{adOneTextOne}</h1>
    //         <p className="text-base lg:text-xl text-gray-800 mt-2">
    //             {adOneTextTwo} 
    //             <span className="font-bold">50%</span>
    //         </p>
    //     </div>
    //     <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
    //         <img src="https://sindu-chart-advertistments.s3.eu-west-1.amazonaws.com/mainAdOne.png" alt="" />
    //         <img src="https://i.ibb.co/J2BtZdg/Rectangle-56-1.png" alt="" />
    //     </div>
    // </div>