import React,{ useEffect, useState } from "react";

const News = () => {

  const [news, setNews] = useState('')

  useEffect(() => {
    // const options = {
    //   mode: 'no-cors',
    //   method: 'GET',
    //   headers: {
    //     'X-BingApis-SDK': 'true',
    //     'X-RapidAPI-Key': '3332bbe7b0msh06d62592ed6490ep161b11jsn3151626c822b',
    //     'X-RapidAPI-Host': 'bing-news-search1.p.rapidapi.com'
    //   }
    // };
    
    fetch('https://www.s1.sinduchart.com/news/news_artical')
      .then(response => response.json())
      .then(response => {
        console.log(response)
        if(response.success == true || response.data.length !== 0){
          setNews(response.data)
        }
        
      })
      .catch(err => console.error(err));
  }, [])



  return (
    <div className="container my-24 px-6 mx-auto">

  <section className="mb-32 text-gray-800 text-center">

    <h2 className="text-3xl font-bold mb-12 pb-4 text-center">Latest articles</h2>

    <div className="grid lg:grid-cols-1 gap-6 xl:gap-x-12">
      {/* <div className="mb-6 lg:mb-0">
        <div className="relative block bg-white rounded-lg shadow-lg">
          <div className="flex">
            <div
              className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg mx-4 -mt-4"
              data-mdb-ripple="true" data-mdb-ripple-color="light">
              <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/024.webp" className="w-full" />
              <a href="#!">
                <div
                  className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"
                  style={{backgroundColor: "rgba(251, 251, 251, 0.15)"}}></div>
              </a>
            </div>
          </div>
          <div className="p-6">
            <h5 className="font-bold text-lg mb-3">My paradise</h5>
            <p className="text-gray-500 mb-4">
              <small>Published <u>13.01.2022</u> by
                <a href="" className="text-gray-900">Anna Maria Doe</a></small>
            </p>
            <p className="mb-4 pb-2">
              Ut pretium ultricies dignissim. Sed sit amet mi eget urna
              placerat vulputate. Ut vulputate est non quam dignissim
              elementum. Donec a ullamcorper diam.
            </p>
            <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light"
              className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Read
              more</a>
          </div>
        </div>
      </div> */}
{/* //2ff28ef3e9a80445cc7ea55382adbe85 */}
{news && news.map((news) => ( 
      <div className="mb-6 lg:mb-0">
        <div className="relative block bg-white rounded-lg shadow-lg">
          <div className="flex">
            <div
              className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg mx-4 -mt-4"
              data-mdb-ripple="true" data-mdb-ripple-color="light">
              <a href= {news.news_data.news_link} target="_blank"
                            rel="noreferrer">
              <img src={news.file_location} className="w-full" />
              
                <div
                  className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"
                   style={{backgroundColor: "rgba(251, 251, 251, 0.15)"}}></div>
              </a>
            </div>
          </div>
          <div className="p-6">
            <h5 className="font-bold text-lg mb-3">{news.news_data.news_title}</h5>
            {/* <p className="text-gray-500 mb-4">
              <small>Published <u>{news.datePublished}</u> by
                <a href="" className="text-gray-900">{news.source.name}</a></small>
            </p> */}
            <p className="mb-4 pb-2">
            {news.news_data.details}
             
            </p>
            <a href= {news.news_data.news_link} target="_blank"
                            rel="noreferrer" data-mdb-ripple="true" data-mdb-ripple-color="light"
              className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Read
              more</a>
          </div>
        </div>
      </div>
))}


      {/* <div className="mb-0">
        <div className="relative block bg-white rounded-lg shadow-lg">
          <div className="flex">
            <div
              className="relative overflow-hidden bg-no-repeat bg-cover relative overflow-hidden bg-no-repeat bg-cover shadow-lg rounded-lg mx-4 -mt-4"
              data-mdb-ripple="true" data-mdb-ripple-color="light">
              <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/081.webp" className="w-full" />
              <a href="#!">
                <div
                  className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"
                  style={{backgroundColor: "rgba(251, 251, 251, 0.15)"}}></div>
              </a>
            </div>
          </div>
          <div className="p-6">
            <h5 className="font-bold text-lg mb-3">Chasing the sun</h5>
            <p className="text-gray-500 mb-4">
              <small>Published <u>10.01.2022</u> by
                <a href="" className="text-gray-900">Joe Svan</a></small>
            </p>
            <p className="mb-4 pb-2">
              Curabitur tristique, mi a mollis sagittis, metus felis mattis
              arcu, non vehicula nisl dui quis diam. Mauris ut risus eget
              massa volutpat feugiat. Donec.
            </p>
            <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light"
              className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Read
              more</a>
          </div>
        </div>
      </div> */}
    </div>

  </section>

</div>
  );
};

export default News;
