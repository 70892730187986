// import React from 'react'
// import AdSense from 'react-adsense';

// const GoogleAds = () => {
//   return (
//     <>
//     1
//     <AdSense.Google
//         client="ca-pub-4645894048105749"
//         slot="6875538843"
//         style={{ width: 500, height: 300, float: 'left' }}
//         />
//     </>
   
//   )
// }

// export default GoogleAds