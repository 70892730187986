import React, { useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import topartists from "../assets/trending/topartists.png";
import topalbums from "../assets/trending/topalbums.png";
import top20sinhala from "../assets/trending/top20sinhala.png";
import to20tamil from "../assets/trending/to20tamil.png";
import tamildaily from "../assets/trending/tamildaily.png";
import sinhaladaily from "../assets/trending/sinhaladaily.png";
import channels from "../assets/trending/channels.png";

const TrendingList = () => {
  return (
    <div className="2xl:mx-auto 2xl:container flex justify-center">
      <div className="2xl:px-20 px-2 py-1 w-full  lg:w-full ">
        {/* Carousel for Small-Sized Screen */}
        <CarouselProvider
          className="relative block sm:hidden"
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={7}
          visibleSlides={2.15}
          step={1}
          infinite={true}
          isPlaying={true}
        >
          <div className="js-flickity flex justify-center items-center">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="w-8 h-8 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-400 absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              id="prev"
            >
              <svg
                width={7}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonBack>
            <Slider>
              <Slide index={0}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={
                        "https://a10.gaanacdn.com/gn_img/albums/kGxbn03y4r/Gxbn22raby/size_m.jpg"
                      }
                      alt="sitting area"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={topartists}
                      alt="sitting area"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Top Artists
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={1}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src="https://a10.gaanacdn.com/gn_img/albums/d41WjznWPL/41Wj2vy6WP/size_m.jpg"
                      alt="chairs"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={topalbums}
                      alt="chairs"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Top Albums
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={2}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src="https://a10.gaanacdn.com/gn_img/albums/d41WjnWPLq/1WjznpV5WP/size_m.jpg"
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={top20sinhala}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Top 20 Sinhala Songs
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={3}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src="https://a10.gaanacdn.com/gn_img/albums/d41WjnWPLq/1WjznpV5WP/size_m.jpg"
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={to20tamil}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Top 20 Tamil Songs
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={4}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src="https://a10.gaanacdn.com/gn_img/albums/d41WjnWPLq/1WjznpV5WP/size_m.jpg"
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={tamildaily}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Tamil Songs Daily Charts
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={5}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src="https://a10.gaanacdn.com/gn_img/albums/d41WjnWPLq/1WjznpV5WP/size_m.jpg"
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={sinhaladaily}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Sinhala Songs Daily Charts
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={6}>
                <div className="gallery-cell lg:mr-7 mr-6 lg:w-1/2 sm:w-96 w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src="https://a10.gaanacdn.com/gn_img/albums/d41WjnWPLq/1WjznpV5WP/size_m.jpg"
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        Lounge Interior
                      </h1>
                    </div>
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={channels}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                      <h1 className="text-xs leading-5 lg:text-2xl lg:leading-normal font-medium text-white">
                        FM Stations Charts
                      </h1>
                    </div>
                  </div>
                </div>
              </Slide>
            </Slider>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="w-8 h-8 md:w-14 md:h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-400 absolute z-30 right-0 mr-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              id="next"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>

        {/* Carousel for Medium and Large-Sized Screen */}
        <CarouselProvider
          className="relative hidden sm:block"
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={7}
          visibleSlides={3}
          step={1}
          infinite={true}
          isPlaying={true}
        >
          <div className="js-flickity flex justify-center items-center">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="w-7 h-7 md:w-5 md:h-5 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-400 absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              id="prev"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonBack>
            <Slider className="carousel__sliderLarge">
              <Slide className="carousel__inner-slideLarge" index={0}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={topartists}
                      alt="sitting area"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-base leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top Artists</h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={topartists}
                      alt="sitting area"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top Artists</h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
              <Slide className="carousel__inner-slideLarge" index={1}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={topalbums}
                      alt="chairs"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top Albums</h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={topalbums}
                      alt="chairs"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top Albums</h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
              <Slide className="carousel__inner-slideLarge" index={2}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={top20sinhala}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top 20 Sinhala Songs</h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={top20sinhala}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top 20 Sinhala Songs</h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
              <Slide className="carousel__inner-slideLarge" index={3}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={to20tamil}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top 20 Tamil Songs</h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={to20tamil}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Top 20 Tamil Songs</h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
              <Slide className="carousel__inner-slideLarge" index={4}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={tamildaily}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Tamil Songs Daily Charts </h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={tamildaily}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Tamil Songs Daily Charts </h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
              <Slide className="carousel__inner-slideLarge" index={5}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={sinhaladaily}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Sinhala Songs Daily Charts</h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={sinhaladaily}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">Sinhala Songs Daily Charts</h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
              <Slide className="carousel__inner-slideLarge" index={6}>
                <div className="gallery-cell w-full h-full">
                  <div className="relative w-full h-full lg:block hidden">
                    <img
                      src={channels}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">FM Stations Charts</h1>
                                        </div> */}
                  </div>
                  <div className="relative w-full h-full lg:hidden">
                    <img
                      src={channels}
                      alt="chair"
                      className="object-center object-cover w-full h-full"
                    />
                    {/* <div className="pl-6 pb-6 lg:pl-8 lg:pb-8 absolute left-0 bottom-0">
                                            <h1 className="text-xl leading-5 lg:text-2xl lg:leading-normal font-medium text-white">FM Stations Charts</h1>
                                        </div> */}
                  </div>
                </div>
              </Slide>
            </Slider>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="w-7 h-7 md:w-5 md:h-5  rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-400 absolute z-30 right-0 mr-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              id="next"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>

      <style>
        {`
                    .gallery-cell {
                        height: 386px;
                        padding-right:15px;
                    }
                    @media (min-width: 300px) and (max-width: 420px) {
                        .gallery-cell {
                            height: 286px !important;
                            
                        }
                    }
                    
                    @media (max-width: 640px) {
                        .gallery-cell {
                            padding-right:0;
                        }
                    }

                    .carousel__sliderLarge {
                        // padding-left: 7%;
                        // padding-right: 7%;
                    }

                    /* gives us the illusion of spaces between the slides */
                    .carousel__inner-slideLarge {
                        width: calc(100% - 20px);
                        height: calc(100% - 20px);
                        left: 10px;
                        top: 10px;
                        
                    }
                `}
      </style>
    </div>
  );
};

export default TrendingList;
