import CountTable from "./CountTable";
import { useState } from "react";
import { FcExpand } from "react-icons/fc";
import Toggle from "./Toggle";

const ReportTile = ({ sindu }) => {
  const [enabled, setEnabled] = useState(false);

  const toggleState = () => {
    setEnabled(!enabled);
  };

  return (
    <div className="p-5 pl-10 m-4 justify-between rounded mb-6 bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900">
      <div className="w-full">
        <p className="mb-2 text-md font-bold text-gray-800 dark:text-gray-100">
          Song - {sindu.title}
        </p>
        <ul>
          <li className="mb-1 text-sm text-gray-600 dark:text-gray-400">
            Station - {sindu.date_stamp_fm_artist.split("_")[1]}
          </li>
          <li className="mb-1 text-sm text-gray-600 dark:text-gray-400">
            Artist -{sindu.date_stamp_fm_artist.split("_")[2]}
          </li>
          <li className="text-sm text-gray-600 dark:text-gray-400 mt-4 flex items-center gap-16">
            {" "}
            <p>Airplay</p> <Toggle on={enabled} onChange={toggleState} />
          </li>
          <li className="text-sm text-gray-600 dark:text-gray-400 mt-4">
            {/* {Object.entries(sindu.airplay_data).map(([k, v]) => `${k}:-${v}`)} */}
            <CountTable airplay_data={sindu.airplay_data} showAll={enabled} />
          </li>

          <li>
            <div className="flex items-center pt-2 pb-2.5"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReportTile;
