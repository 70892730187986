import React, { useEffect, useState, forwardRef } from "react";
import {
  useLazyGetAllFMChannelChartListQuery,
  useLazyGetTopSongsInChannelsQuery,
} from "../services/sinduApi";
import { BsTable, BsGrid3X3GapFill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import DatePicker from "react-datepicker";
import {
  FcMusic,
  FcCustomerSupport,
  FcPhotoReel,
  FcPositiveDynamic,
} from "react-icons/fc";
import sitha from "../assets/fm/logo_sitha.png";
import derana from "../assets/fm/derana.png";
import shaa from "../assets/fm/shaa.png";
import hiru from "../assets/fm/hiru.png";
import shakthi from "../assets/fm/Shakthi.png";
import sooriyan from "../assets/fm/sooriyan.png";
import varnam from "../assets/fm/varnam.png";
import sirasa from "../assets/fm/sirasa.png";
import neth from "../assets/fm/neth.jpg";
import siyatha from "../assets/fm/siyath.png";

import "react-datepicker/dist/react-datepicker.css";
import HashLoader from "react-spinners/HashLoader";

const TopSongsInChannels = () => {
  const [tableView, setTableView] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [listView, setListView] = useState(false);
  const [listLimit, setListLimit] = useState(20);
  const [radioStation, setRadioStation] = useState("Sirasa FM");
  const [filtermonthDisplay, setFiltermonthDisplay] = useState(
    new Date().getMonth() + 1
  );
  const [startMonth, setStartMonth] = useState(new Date());
  const [startYear, setStartYear] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const [filtermonth, setFiltermonth] = useState(
    new Date(new Date().setDate(new Date().getDate())).getMonth() + 1
  );
  const [filterYear, setFilterYear] = useState(
    new Date(new Date().setDate(new Date().getDate())).getFullYear()
  );
  const [filterDay, setFilterDay] = useState(
    new Date(new Date().setDate(new Date().getDate())).getDate()
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="dark:bg-slate-800 dark:text-zinc-500 rounded p-2 text-xs border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200 truncate overflow-hidden"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));
  const MonthFilterInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="dark:bg-slate-800 dark:text-zinc-500 rounded p-2  text-xs border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200 truncate overflow-hidden"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));
  const YearFilterInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="dark:bg-slate-800 dark:text-zinc-500 rounded p-2  text-xs border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200 w-16 sm:w-16 md:w-20 lg:w-20 truncate overflow-hidden"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const [dateFilter, setdateFilter] = useState();
  const [sinduList, setSinduList] = useState();
  const [getChannelCharts, { data: sinhlacharthistory, isLoading, isError , isFetching  }] =
    useLazyGetAllFMChannelChartListQuery();
  useEffect(() => {
    async function fetchData() {
      const response = await getChannelCharts({
        listLimit,
        filterDay,
        filtermonth,
        filterYear,
        radioStation,
      });
      console.log(response);
      setSinduList(response.data.data);
    }
    fetchData();
  }, [startDate, listLimit, radioStation, filtermonth, startYear, startMonth]);



  const TableView = () => {
    return (
      <div className="mt-3 mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
        <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
        <DataFetchingError/>
        <NoDataError/>
          {sinduList && sinduList.length != 0 && (
            <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
              <span className="text-gray-500  flex">
                {" "}
                {filterYear}{"/"}
                {filtermonth == "" ? null : (
                  <p className=""> {filtermonth} </p>
                )}
                {filterDay == "" ? null : (
                  <p className="whitespace-normal">{"/"}{filterDay} </p>
                )}{"  "}
                {radioStation == "" ? (
                  <p className="whitespace-pre"> All Stations </p>
                ) : (
                  <p className="whitespace-pre"> {radioStation} </p>
                )}{" "}
                <p className="whitespace-pre"> Top </p>
                {listLimit}{" "}
                Chart
              </span>
            </p>
          )}
        </div>

        <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
          <div className="">
            <div className="mx-auto container bg-white dark:bg-slate-900 shadow rounded ">
              <div className="w-full  xl:overflow-x-hidden">
                {sinduList && sinduList.length != 0 &&(
                  <table class="min-w-full text-center bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 ">
                    <thead class="border-b dark:border-slate-600 ">
                      <tr>
                        <th
                          scope="col"
                          class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2"
                        >
                          Position
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2"
                        >
                          Title
                        </th>
                        {/* <th
                          scope="col"
                          class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2"
                        >
                          Artist
                        </th> */}
                        <th
                          scope="col"
                          class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2"
                        >
                          Airplay
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sinduList.map((sindu) => (
                        <tr class=" border-b dark:border-slate-700">
                          <td class="px-1 py-2 whitespace-nowrap text-xs font-medium dark:text-gray-400 text-gray-900 ">
                            {sindu.chartpos}
                          </td>
                          <td style={{ maxWidth: '100px' }} class="text-xs dark:text-gray-400 text-slate-900 font-light px-1 py-2  text-left  break-all">
                            <p className="font-semibold">{sindu.title}</p>
                            {/* <p>Album - {sindu.album} </p> */}
                            <p>{sindu.artist && sindu.artist.length!=0 && sindu.artist.map((artist)=>(
                              <span>{" "}{artist.M.name.S}</span>
                            )) }</p>
                            {/* <p className="">Date - {sindu.date_stamp_fm.substring(0, 10)}</p> */}
                          </td>
                          {/* <td class="text-xs dark:text-gray-400 text-gray-900 font-light px-1 py-2 whitespace-nowrap text-left ">
                            {sindu.artist.substring(0, 13)}
                          </td> */}
                          <td class="text-xs dark:text-gray-400 text-gray-900 font-light px-1 py-2 whitespace-nowrap">
                            {sindu.song_count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const GridView = () => {
    return (
      <div className="mt-3 mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
        <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
        <DataFetchingError/>
        <NoDataError/>
          {sinduList && sinduList.length != 0 && (
            <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
              <span className="text-gray-500  flex">
                {" "}
                {filterYear}{"/"}
                {filtermonth == "" ? null : (
                  <p className=""> {filtermonth} </p>
                )}
                {filterDay == "" ? null : (
                  <p className="whitespace-normal">{"/"}{filterDay} </p>
                )}{"  "}
                {radioStation == "" ? (
                  <p className="whitespace-pre"> All Stations </p>
                ) : (
                  <p className="whitespace-pre"> {radioStation} </p>
                )}{" "}
                <p className="whitespace-pre"> Top </p>
                {listLimit}{" "}
                Chart
              </span>
            </p>
          )}
        </div>

        <div className="flex items-stretch flex-wrap xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800 ">
          <div class="container mx-auto">
            <div class="px-4 grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4 justify-between  flex-wrap ">
              {sinduList &&
                sinduList.map((sindu) => (
                  <div className="w-full flex flex-col m-2 pl-2  bg-gradient-to-r from-cyan-50 to-cyan-100 dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900   rounded-lg ">
                    <div className="flex-1  mt-4 mb-3">
                      <div className="content-start">
                        <p className="text-xs m-2 text-gray-90 font-bold dark:text-slate-300 ">
                          {sindu.chartpos} - {sindu.title}
                        </p>
                      </div>
                      <div>
                        {/* <p className="text-xs text-slate-900 m-2 dark:text-slate-400">
                          {" "}
                          Artist - {sindu.artist}
                        </p> */}
                      </div>
                      <div>
                        {/* <p className="text-xs text-slate-800 m-2 dark:text-slate-500">
                          {" "}
                          Language - {sindu.language}
                        </p> */}
                        <p className="text-xs text-slate-700 m-2 dark:text-slate-500">
                          {" "}
                          {/* Album- {sindu.album} */}
                          Album- {replaceAlbumName(sindu.album)}
                        </p>
                        <p className="text-xs text-slate-700 m-2 dark:text-slate-500">
                          {" "}
                          Artist - {sindu.artist && sindu.artist.length!=0 && sindu.artist.map((artist)=>(
                        <span>{" "}{artist.M.name.S}</span>
                      )) }
                        </p>
                        <p className="text-xs text-slate-700 m-2 dark:text-slate-500">
                        Date - {sindu.date_stamp_fm.substring(0, 10)}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center pt-2 pb-2.5">
                      <div className="w-6 h-6 mr-1  rounded-full">
                        <FcMusic />
                      </div>
                      <div className="w-6 h-6 mr-1 shadow rounded-full">
                        <FcCustomerSupport />
                      </div>
                      <div className="w-6 h-6 mr-1 shadow rounded-full">
                        <FcPhotoReel />
                      </div>
                      <div className="w-6 h-6 mr-1 shadow rounded-full">
                        <FcPositiveDynamic />
                      </div>
                    </div>
                    <div className="flex items-end justify-end">
                      <button className="text-xs font-bold leading-3 text-black py-1 px-2 m-2 items-end justify-end rounded bg-gradient-to-r from-cyan-100 to-cyan-300 dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 dark:text-slate-400 focus:outline-none hover:opacity-90">
                      Airplay - {sindu.song_count}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const replaceAlbumName = (e) => {
    console.log("function called");
    if (e == "$Album$") {
      return "Single";
    } else {
      return e;
    }
  };

  const ListView = () => {
    return (
      <div className="mt-3 mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
        <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
        <DataFetchingError/>
        <NoDataError/>
          {sinduList && sinduList.length != 0 && (
            <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
              <span className="text-gray-500  flex">
                {" "}
                {filterYear}{"/"}
                {filtermonth == "" ? null : (
                  <p className=""> {filtermonth} </p>
                )}
                {filterDay == "" ? null : (
                  <p className="whitespace-normal">{"/"}{filterDay} </p>
                )}{"  "}
                {radioStation == "" ? (
                  <p className="whitespace-pre"> All Stations </p>
                ) : (
                  <p className="whitespace-pre"> {radioStation} </p>
                )}{" "}
                <p className="whitespace-pre"> Top </p>
                {listLimit}{" "}
                Chart
              </span>
            </p>
          )}
        </div>

        <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
          {sinduList &&
            sinduList.map((sindu) => (
              <div className="p-5 pl-10 m-4 flex justify-between rounded mb-6 bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900">
                <div className="w-3/5">
                  <p className="mb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                    {sindu.chartpos} - {sindu.title}
                  </p>
                  <ul>
                    {/* <li className="mb-1 text-xs text-gray-600 dark:text-gray-400">
                      Artist - {sindu.artist}
                    </li> */}
                    <li className="mb-1 text-xs text-gray-600 dark:text-gray-400">
                       {/* Album - {sindu.album} */}
                       Album - {replaceAlbumName(sindu.album)}
                    </li>
                    <li className="mb-1 text-xs text-gray-600 dark:text-gray-400">
                      Artist - {sindu.artist && sindu.artist.length!=0 && sindu.artist.map((artist)=>(
                        <span>{" "}{artist.M.name.S}</span>
                      )) }
                    </li>
                    {/* <li className="text-xs text-gray-600 dark:text-gray-400">
                      Language - {sindu.language}
                    </li> */}
                    <li>
                      <div className="flex items-center pt-2 pb-2.5">
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                          <FcMusic />
                        </div>
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                          <FcCustomerSupport />
                        </div>
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                          <FcPhotoReel />
                        </div>
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                          <FcPositiveDynamic />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="w-3/12 flex flex-col items-end justify-between">
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    Date - {sindu.date_stamp_fm.substring(0, 10)}{" "}
                  </p>
                  <div className="bg-gradient-to-r from-white to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 h-6 w-20 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                    <span className="text-xs text-slate-500 font-extrabold dark:text-gray-300 font-normal">
                    Airplay - {sindu.song_count}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const DataFetchingError = () => {
    return(
        <>
            {
                isError == true && (
                <div className="items-center w-full mt-14">
                    <div role="alert w-full">
                    <div class="bg-red-300 text-white dark:text-red-800 font-bold rounded-t px-4 py-2 dark:bg-red-500 dark:bg-opacity-30">
                        Something Went Wrong!
                    </div>
                    <div class="border border-t-0 border-red-300 rounded-b bg-red-100 px-4 py-3 text-red-800 dark:bg-red-900 dark:bg-opacity-10 dark:border-red-900">
                        <p>Unavailable to load data.<br/>Please wait a moment and try Again.</p>
                    </div>
                    </div>
                </div>
                )
            }
        </>
    )
  }
  const NoDataError = () => {
    return(
        <>
            {  
                sinduList && (sinduList.length == 0) && (
                <div className="items-center w-full mt-14">
                    <div class="bg-teal-50 border-t-4 border-teal-300 dark:border-teal-800 rounded-b text-slate-500 px-4 py-3 shadow-md dark:bg-teal-900 dark:bg-opacity-20 dark:text-teal-600 " role="alert">
                    <div class="flex">
                        <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-300 dark:text-teal-800 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                        <div>
                        <p class="font-bold">Data loading has Failed to selected Date</p>
                        <p class="text-sm">Please select another date or wait a moment and try again.</p>
                        </div>
                    </div>
                    </div>
                </div>
                )
            }
        </>
    )
  }

  const Loader = () => {
    return (
        <> 
      <div className="mt-3 mx-auto bg-white dark:bg-gray-800 shadow rounded w-full min-h-max ">
        <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-center items-center">
          <div className="mt-28 mb-28 ">
            <div>
                <HashLoader
                  color='#36d7b7'
                  // loading={isFetching}
                  // cssOverride={override}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={2}
                />
              </div>
            </div>
        </div>
      </div>
        </>
    )
  }

  //  {filterYear} {filtermonthDisplay}  {filterDay==''? null : <p className='whitespace-pre'> {filterDay} </p>}  Top{(radioStation=="")?<p className='whitespace-pre'> All Stations </p>:<p className='whitespace-pre'> {radioStation} FM </p>}  Songs List

  return (
    <div className="container  ">
      {/* {startDate && console.log(startDate)} */}
      <div className="py-8">
        <div>
          <h2 className="text-xl font-semibold leading-tight dark:text-white text-center md:text-left ">
          Radio Top Chart
          </h2>
        </div>
        <div className="inline-flex mt-2 xs:mt-0 "></div>
        <div className="justify-center flex mt-1 mb-2 space-x-4 sm:justify-center sm:mt-0">
          <a
            href="https://sithafm.lk/"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            target="_blank"
            rel="noreferrer"
          >
            <img src={sitha} className="h-5 w-19 sm:h-8 sm:w-12" />
            <span className="sr-only">Sitha fm</span>
          </a>
          <a
            href="http://www.fmderana.lk/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img src={derana} className="h-5 w-5 sm:h-8 sm:w-8" />
            <span className="sr-only">derana fm</span>
          </a>
          <a
            href="https://www.shaafm.lk/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-4 sm:h-8 sm:w-6" src={shaa} />
            <span className="sr-only">shaa fm</span>
          </a>
          <a
            href="https://www.hirufm.lk/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-5 sm:h-8 sm:w-8" src={hiru} />
            <span className="sr-only">hiru fm</span>
          </a>
          <a
            href="https://shakthifm.com/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-5 sm:h-8 sm:w-8" src={shakthi} />
            <span className="sr-only">shakthi fm</span>
          </a>
          <a
            href="https://www.sooriyanfm.lk/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-7 sm:h-8 sm:w-10" src={sooriyan} />
            <span className="sr-only">sooriyan fm</span>
          </a>
          <a
            href="#"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-4 sm:h-9 sm:w-7" src={varnam} />
            <span className="sr-only">varnam fm</span>
          </a>
        </div>
        <div className="justify-center flex mt-4 mb-3 space-x-4 sm:justify-center sm:mt-0">
          <a
            href="#"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-5 sm:h-8 sm:w-8" src={sirasa} />
            <span className="sr-only">hiru fm</span>
          </a>
          <a
            href="https://siyathafm.lk/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-5 sm:h-8 sm:w-8" src={siyatha} />
            <span className="sr-only">hiru fm</span>
          </a>
          <a
            href="https://www.nethfm.com/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="h-5 w-5 sm:h-8 sm:w-8" src={neth} />
            <span className="sr-only">hiru fm</span>
          </a>
        </div>

        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-end xs:justify-between   dark:bg-gray-900 dark:text-zinc-500 dark:border-zinc-900  ">
          <div className="inline-flex mt-2 xs:mt-0 ">
            
            <button
              className="text-sm bg-gray-200 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l  dark:bg-slate-700 dark:text-zinc-500 dark:hover:bg-slate-900 "
              onClick={() => {
                setTableView(true);
                setGridView(false);
                setListView(false);
              }}
            >
              <BsTable />
            </button>
            <button
              className="text-sm bg-gray-200 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-1 dark:bg-slate-700 dark:text-zinc-500 dark:border-zinc-900 dark:hover:bg-slate-900 "
              onClick={() => {
                setTableView(false);
                setGridView(true);
                setListView(false);
              }}
            >
              <BsGrid3X3GapFill />
            </button>
            <button
              className="text-sm bg-gray-200 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r dark:bg-slate-700 dark:text-zinc-500 dark:border-zinc-900 dark:hover:bg-slate-900 "
              onClick={() => {
                setTableView(false);
                setGridView(false);
                setListView(true);
              }}
            >
              <FaThList />
            </button>
          </div>
        </div>
        <div className="px-5  flex flex-col xs:flex-row items-end xs:justify-between   dark:bg-gray-900 dark:text-zinc-500 dark:border-zinc-900  ">
          <div className="flex flex-wrap  xs:mt-0 gap-px sm:gap-px md:gap-1">


          <div className=" rounded-lg">
                <p className="text-xs text-slate-300 dark:text-slate-600 text-center truncate overflow-hidden ">Filter FM Station</p>
                <div className="relative">
                    <select
                        className="appearance-none text-xs h-full rounded  block w-full py-2 px-4 pr-8 leading-tight focus:outline-none dark:bg-slate-800 dark:text-zinc-500  border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200"
                        defaultValue={"Sirasa FM"}
                        onChange={(e) => setRadioStation(e.target.value)}
                    >
                        {/* <option value={""}>All Stations</option> */}
                        <option value={"FM Derana"}>FM Derana</option>
                        <option value={"Shaa FM"}>Shaa FM</option>
                        <option value={"Sitha FM (සිත 88.6)"}>Sitha FM</option>
                        <option value={"Hiru FM"}>Hiru FM</option>
                        <option value={"Shakthi FM"}>Shakthi FM</option>
                        <option value={"Sooriyan FM"}>Sooriyan FM</option>
                        <option value={"Varnam FM"}>Varnam FM</option>
                        <option value={"Sirasa FM"}>Sirasa FM</option>
                        <option value={"Neth FM"}>Neth FM</option>
                        <option value={"Siyatha FM"}>Siyatha FM</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 ">
                        <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
         </div>

         <div className=" rounded-lg">
                <p className="text-xs text-slate-300 dark:text-slate-600 text-center truncate overflow-hidden ">Filter Limit</p>
                    <div className="relative">
                    <select
                        className="appearance-none h-full rounded  block text-xs w-full py-2 px-4 pr-8 leading-tight focus:outline-none dark:bg-slate-800 dark:text-zinc-500  border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200"
                        defaultValue={20}
                        onChange={(e) => setListLimit(e.target.value)}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 ">
                        <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                    </div>
         </div>

         <div className=" rounded-lg ">
                <p className="text-xs text-slate-300 dark:text-slate-600 text-start sm:mx-1 md:mx-2 truncate overflow-hidden">Filter by Date</p>
            <div className="items-start  ">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  const monthName = date.toLocaleString("default", {
                    month: "long",
                  });
                  setFiltermonthDisplay(monthName);

                  var d = date,
                    month = "" + (d.getMonth() + 1),
                    day = "" + d.getDate(),
                    year = d.getFullYear();

                  if (month.length < 2) month = "0" + month;
                  if (day.length < 2) day = "0" + day;

                  let filterDate = [year, month, day].join("-");
                  setdateFilter(filterDate);
                  setFilterYear(year);
                  setFiltermonth(month);
                  setFilterDay(day);
                }}
                customInput={<ExampleCustomInput />}
                dateFormat="MMMM d, yyyy"
                maxDate={new Date()}
                minDate={new Date('2022-03-11')}
              />
            </div>
         </div>

         <div className=" rounded-lg ">
                <p className="text-xs text-slate-300 dark:text-slate-600 text-start sm:mx-1 md:mx-2 truncate overflow-hidden">Filter by Month</p>
            <div className="items-start ">
              <DatePicker
                selected={startMonth}
                onChange={(date) => {
                  const monthName = date.toLocaleString("default", {
                    month: "long",
                  });
                  setFiltermonthDisplay(monthName);
                  setStartMonth(date);
                  var d = date,
                    month = "" + (d.getMonth() + 1),
                    day = "" + d.getDate(),
                    year = d.getFullYear();

                  if (month.length < 2) month = "0" + month;
                  if (day.length < 2) day = "0" + day;

                  let filterDate = [year, month, day].join("-");
                  setdateFilter(filterDate);
                  setFilterYear(year);
                  setFiltermonth(month);
                  setFilterDay("");
                  console.log(`station : ${radioStation}`);
                }}
                dateFormat="MMMM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                customInput={<MonthFilterInput />}
                maxDate={new Date()}
                minDate={new Date('2022-02-28')}
              />
            </div>
     </div>

     <div className=" rounded-lg ">
            <p className="text-xs text-slate-300 dark:text-slate-600 text-start sm:mx-1 md:mx-2 truncate overflow-hidden w-17">Filter by Year</p>
            <div className="items-start ">
              <DatePicker
                selected={startYear}
                onChange={(date) => {
                  setStartYear(date);
                  var d = date,
                    month = "" + (d.getMonth() + 1),
                    day = "" + d.getDate(),
                    year = d.getFullYear();

                  if (month.length < 2) month = "0" + month;
                  if (day.length < 2) day = "0" + day;

                  let filterDate = [year, month, day].join("-");
                  setdateFilter(filterDate);
                  setFilterYear(year);
                  setFiltermonthDisplay("");
                  setFiltermonth("");
                  setFilterDay("");
                }}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={8}
                customInput={<YearFilterInput />}
                maxDate={new Date()}
                minDate={new Date('2021-12-31')}
              />
            </div>
     </div>

          </div>
        </div>

        {isFetching == true ? <Loader/>: tableView == true ? (
            <TableView />
          ) : tableView == false && listView == false && gridView == true ? (
            <GridView />
          ) : (
            <ListView />
          )
        }
      </div>
    </div>
  );
};

export default TopSongsInChannels;
