import React,{ useEffect, useState, forwardRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useLazyGetSinhalaDailyChartHistoryQuery, useLazyGetsinhalaDailyChartQuery, useLazyGetTamilDailyChartQuery, useLazyGetTopSongsInChannelsQuery } from '../services/sinduApi'
import {BsTable , BsGrid3X3GapFill, BsCardList} from 'react-icons/bs'
import {FaThList} from 'react-icons/fa'
import DatePicker from "react-datepicker";
import {FcMusic, FcCustomerSupport, FcPhotoReel, FcPositiveDynamic} from 'react-icons/fc'
import sitha from '../assets/fm/logo_sitha.png'
import derana from '../assets/fm/derana.png'
import shaa from '../assets/fm/shaa.png'
import hiru from '../assets/fm/hiru.png'
import shakthi from '../assets/fm/Shakthi.png'
import sooriyan from '../assets/fm/sooriyan.png'
import varnam from '../assets/fm/varnam.png'

import "react-datepicker/dist/react-datepicker.css";


const Home = () => {

    let navigate = useNavigate();


    
  return (


    <div className="container m-auto ">
      {/* {startDate && console.log(startDate)} */}
    <div className="py-8">
        <div>
            {/* <h2 className="text-xl font-semibold leading-tight dark:text-white">Top Songs in Radio Stations</h2> */}
        </div>
        <div className="inline-flex mt-2 xs:mt-0 ">
            {/* <h2 className="text-xl font-semibold leading-tight dark:text-white">Sinhala Daily Top 20 Songs</h2> */}
            
        </div>
        
        
        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-end xs:justify-between   dark:bg-gray-900 dark:text-zinc-500 dark:border-zinc-900  ">

          
                      
                            
                        <div className="inline-flex mt-2 xs:mt-0 ">
                        <div className="relative mx-0 sm:mx-1">
                                {/* <select
                                    className="appearance-none h-full rounded border block appearance-none w-full py-2 px-4 pr-8 leading-tight focus:outline-none dark:bg-slate-800 dark:text-zinc-500  border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200"
                                    defaultValue={""}
                                    // onChange={(e) => setRadioStation(e.target.value) }
                                    >
                                    <option value={""}>All Stations</option>
                                    <option value={"Derana"}>Derana</option>
                                    <option value={"Sha"}>Shaa</option>
                                    <option value={"Sitha"}>Sitha</option>
                                    <option value={"Hiru"}>Hiru</option>
                                    <option value={"Shakthi"}>Shakthi</option>
                                    <option value={"Sooriyan"}>Sooriyan</option>
                                    <option value={"Varnam"}>Varnam</option>
                                </select>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 ">
                                   <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                          <div className="relative">
                          <select
                              className="appearance-none h-full rounded border block appearance-none w-full py-2 px-4 pr-8 leading-tight focus:outline-none dark:bg-slate-800 dark:text-zinc-500  border-gray-400 dark:border-transparent hover:bg-slate-300 hover:text-slate-800 text-gray-600 bg-slate-200"
                              defaultValue={20}
                            //   onChange={(e) => setListLimit(e.target.value)}
                              >
                              
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={15}>15</option>
                              <option value={20}>20</option>
                          </select>
                          <div
                              className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 ">
                              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                          </div> */}
                      </div>
                      {/* <div className='items-start mx-0 sm:mx-1 md:mx-2'>
                          <DatePicker
                            //   selected={startDate}
                            //   onChange={(date) => setStartDate(date)}
                            //   customInput={<ExampleCustomInput />}
                              maxDate={new Date()}
                            />
                      </div>     */}
                        {/* <button
                            className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l  dark:bg-slate-700 dark:text-zinc-500 dark:hover:bg-slate-900 "
                            onClick={()=> {
                                setTableView(true)
                                setGridView(false)
                                setListView(false)
                             }}
                            >
                            <BsTable />
                        </button>
                        <button
                            className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-1 dark:bg-slate-700 dark:text-zinc-500 dark:border-zinc-900 dark:hover:bg-slate-900 "
                            onClick={()=> {
                                setTableView(false)
                                setGridView(true)
                                setListView(false)
                             }}
                            >
                            <BsGrid3X3GapFill />
                        </button>
                        <button
                            className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r dark:bg-slate-700 dark:text-zinc-500 dark:border-zinc-900 dark:hover:bg-slate-900 " 
                            onClick={()=> {
                                setTableView(false)
                                setGridView(false)
                                setListView(true)
                             }}
                            >
                            <FaThList />
                        </button> */}
                    </div>
               
                </div>

            <div className="mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
                <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
                    <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
                        
                        <div className="flex  w-full  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  gap-8">
                            {/* <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/`)}
                                >
                                <div className="p-4  rounded">
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACiklEQVRoge2Zz2sTQRiG32+SGkhia1MQQbxIT61QRGpsL1bjr0JS8JCLvduL/R/0f9BLz40KES9SRKUR8RArBgraoGiPxZOCJBs17u7rIVW0yGbSzO6q7AO5hNlvvmfz7szuBoiIiIgIEwm7gV/hVajWk+xRN8aCEMX06vPxbsfEg2jMCxaOJVuWyrmCvPVU5qB4QKh/fCgCjXPT+8W1L4AoWi2chSCx2ygEJtA4NTkuInmBFOjY0zAUX98EODMTb8Q/n1BEEeBFAIcAgOghHxoYFfh0fiqjvjk5UShYbM0pYshk/T/Rt8CXM5OHbaoCgDxs5yQEA4ZPsid9C9iUTQTZ8Q5UaDMbIhIwRJ2Ca4R03Xl3EuZOXKegTOXcHnxYe/3jy2bueE9FghboNO24twYfv3hjomAQAnUKyq5IaejR2lvTxf0QcIVSJXjPhnNnX6W26cMcPzEqQMgVFbfvph7U3pus64VRgb2razdM1tPhb1lGd00kEDb/vEAoOzFnRxPN9vCCiFwCcQQAIHhFoNTrjW3gAq3TUwettrMiwMRvzRJZAbK91gs0QpwdTbjirACYMFUzUIFme3gBBpsHNCNkKrMCNW/66a2rgMnMCjhm+uHTM0J+ZLYHGjqDPAVMZ5aQuv5YbOiM8xToZNYcFC7rD0ZJZ1gXAY5pT6hBeuDjEoB1jaHr6RFrSaemyWW0a2bl/ruvirE8vCXWFWN5KW+0dSb1voh9yGyyUt1KZawsiUUCzwRoCtAkUCWxmMpY2WSluqU7r+cySuGyEHqvCTQzCwDbZ/f69qcvPH8BPzJrGu+L2IfMmkbrTwYWx/c0P6QuQzCv0LmVcIGXIG6mR6ylsJqPiIj4D/gOCSvuNDpQP6oAAAAASUVORK5CYII="/>                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Top 20 Sinhala Daily Chart</h3>
                                </div>
                            </div>
                            <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/top20-tamil-daily-chart`)}
                                >
                                <div className="p-4 -700 rounded text-white">
                                <img src="https://img.icons8.com/color/48/000000/playlist.png"/>
                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Top 20 Tamil Daily Chart</h3>
                                </div>
                            </div> */}
                            <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/`)}
                                >
                                <div className="p-4  rounded text-white">
                                <img src="https://img.icons8.com/nolan/64/musical-notes.png"/>
                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Sinhala Songs Chart </h3>
                                    {/* <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-normal leading-5">Click Rate</p> */}
                                </div>
                            </div>
                            <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/tamil-songs-chart`)}
                                >
                                <div className="p-4  rounded text-white">
                                <img src="https://img.icons8.com/color/48/000000/portable-speaker2.png"/>
                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Tamil Songs Chart </h3>
                                    {/* <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-normal leading-5">Click Rate</p> */}
                                </div>
                            </div>
                            <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/top-albums`)}
                                >
                                <div className="p-4  rounded text-white">
                                <img src="https://img.icons8.com/color/48/000000/phonograph.png"/>
                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Top Albums</h3>
                                    <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-normal leading-5">Sinhala / Tamil</p>
                                </div>
                            </div>
                            <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/top-albums`)}
                                >
                                <div className="p-4  rounded text-white">
                                <img src="https://img.icons8.com/color/48/000000/us-music.png"/>
                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Top Artists</h3>
                                    <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-normal leading-5">Sinhala / Tamil</p>
                                </div>
                            </div>
                            <div className="bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900  hover:bg-teal-50 dark:hover:bg-neutral-900 rounded shadow px-8 py-6 flex items-center"
                                onClick={()=> navigate(`/top-songs-in-channels`)}

                                >
                                <div className="p-4  rounded text-white">
                                <img src="https://img.icons8.com/color/48/000000/treble-clef.png"/>
                                </div>
                                <div className="ml-6">
                                    <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-sm">Top Charts in FM Stations</h3>
                                    {/* <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-normal leading-5">Click Rate</p> */}
                                </div>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </div>
        



    </div>
</div>

  )
}

export default Home;


 
