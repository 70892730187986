const CountTable = ({ airplay_data, showAll }) => {
  const playObj = {};

  Object.entries(airplay_data).forEach(([k, v]) => {
    let obj = { date: k, count: v };
    let dateIndex = k.split("-")[2];
    playObj[dateIndex] = obj;
  });

  return (
    <div className="m-4 mx-12">
      <table>
        <thead>
          <tr>
            <th className="text-center">Date</th>
            <th className="text-center px-16">Airplay</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(playObj).map(([k, v]) => {
            if (!showAll && v.count == 0) {
              return "";
            }
            return (
              <tr key={k}>
                <td className="text-left">{v.date}</td>
                <td className="text-center">{v.count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CountTable;
