import { createSlice } from "@reduxjs/toolkit";


export const reportSlice = createSlice({
    name: "report",
    initialState: {
      artistArray:[]
    },
    reducers: {
   
      artistArraySuccess: (state, action) => {
        return {
          ...state,
          artistArray: action.payload,
        };
      },
  
   
    },
  });

  export const { artistArraySuccess } =
  reportSlice.actions;

export default reportSlice.reducer;