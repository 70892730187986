import React,{ useEffect, useState } from 'react'
import { useLazyGetTamilDailyChartQuery, useLazyGetTop20TamilTodaySongsListQuery } from '../services/sinduApi'
import {BsTable , BsGrid3X3GapFill} from 'react-icons/bs'
import {FaThList} from 'react-icons/fa'
import {FcMusic, FcCustomerSupport, FcPhotoReel, FcPositiveDynamic} from 'react-icons/fc'



const Top20TamilDailyChart = () => {

      const [tableView, setTableView] = useState(true)
      const [gridView, setGridView] = useState(false)
      const [listView, setListView] = useState(false)
      const [showDropdown, setShowDropdown] = useState(false);
      const [todayDate, setTodayDate] = useState(new Date())
      

      const [sinduList, setSinduList] = useState()
      const [getTamilDailyChart, { data: tamilchart }] = useLazyGetTop20TamilTodaySongsListQuery();
      useEffect(() => {
        async function fetchData() {
            let today = new Date() 
            let month = '' + (today.getMonth() + 1)
            let day = '' + today.getDate()
            let year = today.getFullYear()
    
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
              let filterDay = day
              let filterYear = year
              let filterMonth = month
          //   setFilterDay(day);
          //   setFilterMonth(month);
          //   setFilterYear(year);
          console.log(filterDay, filterYear, filterMonth);
          const response = await getTamilDailyChart({filterYear, filterMonth, filterDay});
          console.log(response);
          setSinduList(response.data.data)
        }
        fetchData();
      }, []);

      const TableView = () => {
        return(
            <div className="mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
            <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
            {sinduList && (sinduList.length!= 0 )&&
                <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
                    
                    <span className="text-gray-500">{todayDate.toString().slice(0,16) }</span>
                </p>
            }
                {/* <div className="relative">
                    <div onclick={() => setShowDropdown(!showDropdown)} className="relative z-0 bg-gray-100 dark:bg-gray-700 flex items-center justify-between w-full cursor-pointer text-xs form-select text-gray-600 dark:text-gray-400 md:p-3 p-2 rounded bg-transparent" data-menu>
                        <p className="leading-3 tracking-normal font-normal text-sm">
                            Show:
                            <span className="text-indigo-700 dark:text-indigo-600 mr-2">This week</span>
                        </p>
                        <div className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" className="hidden icon icon-tabler icon-tabler-chevron-up" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <polyline points="6 15 12 9 18 15" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-up" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </div>
                    </div>
                    {showDropdown && (
                        <ul className="invisible z-10 transition duration-300 opacity-0 bg-white dark:bg-gray-700 shadow rounded mt-2 w-40 py-1 absolute">
                            <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal">Previous week</li>
                            <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal">Next week</li>
                        </ul>
                    )}
                </div> */}
            </div>
            {/* <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-4 bg-white dark:bg-gray-800">
                <div className="xl:flex lg:flex md:flex sm:flex">
                    <div className="xl:w-1/4 w-full mb-2 xl:mb-0">
                        <p className="text-sm text-gray-600 dark:text-gray-400 font-bold">Tasks Scheduled: 24</p>
                    </div>
                    <div className="xl:w-1/4 w-full mb-2 xl:mb-0">
                        <p className="text-sm text-gray-600 dark:text-gray-400 font-bold">Tasks Pending: 15</p>
                    </div>
                    <div className="xl:w-1/4 w-full mb-2 xl:mb-0">
                        <p className="text-sm text-gray-600 dark:text-gray-400 font-bold">Tasks Completed: 09</p>
                    </div>
                </div>
            </div> */}
            
            <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">

            <div className="">
            <div className="mx-auto container bg-white dark:bg-slate-900 shadow rounded ">
                <div className="w-full  xl:overflow-x-hidden">
                    {sinduList && 
                    <table class="min-w-full text-center bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 " >
                    <thead class="border-b dark:border-slate-600 ">
                      <tr>
                        <th scope="col" class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2">
                          Rating
                        </th>
                        <th scope="col" class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2">
                          Title
                        </th>
                        <th scope="col" class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2">
                          Artist
                        </th>
                        <th scope="col" class="text-sm font-medium text-gray-900 dark:text-gray-400 px-4 py-2">
                          Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    { sinduList.map((sindu) => (
                      <tr class=" border-b dark:border-slate-700">
                        <td class="px-1 py-2 whitespace-nowrap text-xs font-medium dark:text-gray-400 text-gray-900">{sindu.chartpos}</td>
                        <td class="text-xs dark:text-gray-400 text-slate-900 font-light px-1 py-2 whitespace-nowrap text-left ">
                        {sindu.title.substring(0, 15)}
                        </td>
                        <td class="text-xs dark:text-gray-400 text-gray-900 font-light px-1 py-2 whitespace-nowrap text-left ">
                        {sindu.artist.substring(0, 13)}
                        </td>
                        <td class="text-xs dark:text-gray-400 text-gray-900 font-light px-1 py-2 whitespace-nowrap">
                        {sindu.song_count}
                        </td>
                      </tr >
                     ))}
                    </tbody>
                  </table>
                    // <table className="min-w-full bg-cyan-50  dark:bg-slate-900 rounded">
                    //     <thead>
                    //         <tr className="w-full h-16 border-gray-300 dark:border-gray-800 border-b py-8">
                                
                    //             <th className="text-gray-600 dark:text-gray-400 font-normal   text-sm tracking-normal leading-4 text-center">RATING</th>
                    //             <th className="text-gray-600 dark:text-gray-400 font-normal   text-sm tracking-normal leading-4 text-center">TITLE</th>
                    //             <th className="text-gray-600 dark:text-gray-400 font-normal   text-sm tracking-normal leading-4 text-center">ARTIST NAME</th>
                    //             <th className="text-gray-600 dark:text-gray-400 font-normal   text-sm tracking-normal leading-4 text-center">COUNT</th>
                    //        </tr>
                    //     </thead>
                    //     <tbody>
                    //         { sinduList.map((sindu) => (
                    //         <>
                    //         <tr className=" border-gray-300 dark:border-gray-800  dark:border-b">
                               
                               
                    //             <td className="text-sm pr-6 text-center whitespace-no-wrap text-gray-800 dark:text-gray-500 tracking-normal leading-4">{sindu.chartpos}</td>
                    //             <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-500 tracking-normal leading-4">{sindu.title}</td>
                    //             <td className="pr-6 whitespace-no-wrap">
                    //                 <div className="flex items-center">
                    //                     {/* <div className="h-8 w-8">
                    //                         <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/advance_tables/at_2.png" alt className="h-full w-full rounded-full overflow-hidden shadow" />
                    //                     </div> */}
                    //                     <p className="ml-2 text-gray-800 dark:text-gray-500 tracking-normal leading-4 text-sm">{sindu.artistsname}</p>
                    //                 </div>
                    //             </td>
                    //             <td className="text-center text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-500 tracking-normal leading-4">{sindu.count}</td>
                                
                    //         </tr>
                    //         <tr>
                    //             <td></td>
                    //             <td></td>
                    //             <td></td>
                    //             <td></td>
                    //         </tr>
                    //         </>
                    //         ))}
                    //     </tbody>
                    // </table>
                    }
                </div>
            </div>
        </div>

            </div>
        </div>
        )
      }

      const GridView= () => {
        return(
            <div className="mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
            <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
            {sinduList && (sinduList.length!= 0 )&&
                <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
                    <span className="text-gray-500">{todayDate.toString().slice(0,16) }</span>
                </p>
            }
                
            </div>
            
            
            <div className="flex items-stretch flex-wrap xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800 ">

            <div class="container mx-auto">
                <div class="px-4 grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4 justify-between  flex-wrap ">
            {sinduList && sinduList.map((sindu) => (


                // <div className="rounded shadow-lg flex-1 dark:bg-gray-900 bg-cyan-50 sm:mx-2 md:mx-1 lg:mx-2 w-52 sm:w-1/3 lg:pt-0  mb-10 flex flex-col">
                //     <div className="w-52 rounded  py-4 px-5 items-stretch ">
                //         <div className="flex items-start justify-between">
                //             <div className="flex items-center mb-4 lg:mb-0 ">
                //                 <div className="w-14 h-14 bg-cover rounded-md mr-3">
                //                     <img src="https://img.freepik.com/free-vector/music-record-vinyl-with-audio-beats_1017-30116.jpg?size=338&ext=jpg&ga=GA1.2.292235293.1664259754" alt className="rounded-full h-full w-full overflow-hidden shadow" />
                //                 </div>
                //                 <div>
                //                     <p className="break-all text-sm font-bold leading-4 text-gray-800 dark:text-gray-100">{sindu.chartpos} - {sindu.albumname}</p>
                //                     <p
                //                         className="break-all text-xs leading-3 text-gray-500 
                //                     dark:text-gray-400 pt-2"
                //                     >
                //                        Artist - {sindu.artistsname}
                //                     </p>
                //                 </div>
                //             </div>
                          
                //         </div>
                //         <p className="break-all pt-4 text-xs leading-4 text-gray-600">Label - {sindu.label}</p>
                //         <p className="break-all text-xs leading-4 text-gray-600">Title - {sindu.title}</p>
                //         <p className=" break-all text-xs leading-4 text-gray-600">Date - {sindu.chartdate}</p>
                //         <p className="text-xs font-medium leading-3 pt-5 text-indigo-700">Hello!</p>
                //         <div className="flex items-center pt-2 pb-2.5">
                //             <div className="w-6 h-6 mr-1 shadow rounded-full">
                //                 {/* <img alt="img-1" src={"https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"} className="w-full h-full object-cover object-center rounded-full" /> */}
                //                 <FcMusic />
                //             </div>
                //             <div className="w-6 h-6 mr-1 shadow rounded-full">
                //                 {/* <img alt="img-1" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80" className="w-full h-full object-cover object-center rounded-full" /> */}
                //                 <FcCustomerSupport/>
                //             </div>
                //             <div className="w-6 h-6 mr-1 shadow rounded-full">
                //                 {/* <img alt="img-1" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" className="w-full h-full object-cover object-center rounded-full" /> */}
                //                 <FcPhotoReel/>
                //             </div>
                //             <div className="w-6 h-6 mr-1 shadow rounded-full">
                //                 {/* <img alt="img-1" src="https://images.unsplash.com/photo-1513956589380-bad6acb9b9d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" className="w-full h-full object-cover object-center rounded-full" /> */}
                //                 <FcPositiveDynamic />
                //             </div>
                //         </div>
                //         <div className="flex items-end justify-end">
                //             <button className="text-xs font-medium leading-3 text-white py-3 px-4 rounded bg-indigo-700 focus:outline-none hover:opacity-90">COUNT - {sindu.count}</button>
                //         </div>
                //     </div>
                // </div>
                //bg-gradient-to-r from-cyan-50 to-cyan-100
                //bg-no-repeat bg-cover bg-center style={{ backgroundImage: `url(https://i.pinimg.com/736x/fe/ec/40/feec40fab864ac0c9ae243b471654d74.jpg)`}}
                <div className="w-full flex flex-col m-2 pl-2  bg-gradient-to-r from-cyan-50 to-cyan-100 dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900   rounded-lg " >
                    {/* <div className="w-full flex ">
                        <div className="w-10 m-2 h-10 bg-cover rounded-md content-start ">
                            <img src="https://img.freepik.com/free-vector/music-record-vinyl-with-audio-beats_1017-30116.jpg?size=338&ext=jpg&ga=GA1.2.292235293.1664259754" alt className="justify-start rounded-full h-full w-full overflow-hidden shadow" />
                        </div>
                    </div> */}
                    <div className="flex-1  mt-4 mb-3">
                        <div className='content-start'>
                            <p className="text-xs m-2 text-gray-90 font-bold dark:text-slate-300 ">{sindu.chartpos} - {sindu.album}</p>
                            {/* <p className="text-base sm:text-lg md:text-xl 2xl:text-2xl text-gray-900">28</p> */}
                        </div>
                        <div >
                            <p className="text-xs text-slate-900 m-2 dark:text-slate-400"> Artist - {sindu.artist}</p>
                            {/* <p className="text-base sm:text-lg md:text-xl 2xl:text-2xl text-gray-50">$2890</p> */}
                        </div>
                        <div >
                            <p className="text-xs text-slate-800 m-2 dark:text-slate-500"> Language - {sindu.language}</p>
                            <p className="text-xs text-slate-700 m-2 dark:text-slate-500"> Title - {sindu.title}</p>
                            {/* <p className="text-xs text-gray-100 m-2"> Date - {sindu.chartdate}</p> */}
                            {/* <p className=" text-base sm:text-lg md:text-xl 2xl:text-2xl text-gray-50">$169</p> */}
                        </div>
                    </div>
                    <div className="flex items-center pt-2 pb-2.5">
                        <div className="w-6 h-6 mr-1  rounded-full">
                            <FcMusic />
                        </div>
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                            <FcCustomerSupport/>
                        </div>
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                            <FcPhotoReel/>
                        </div>
                        <div className="w-6 h-6 mr-1 shadow rounded-full">
                            <FcPositiveDynamic />
                        </div>
                    </div>
                    <div className="flex items-end justify-end">
                        <button className="text-xs font-bold leading-3 text-black py-1 px-2 m-2 items-end justify-end rounded bg-gradient-to-r from-cyan-100 to-cyan-300 dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 dark:text-slate-400 focus:outline-none hover:opacity-90">COUNT - {sindu.song_count}</button>
                    </div>
                </div>
           


             ))}
             
            


             </div>
             </div>


            </div>
        </div>
        )
      }

      const ListView= () => {
        return(
        
        <div className="mx-auto bg-white dark:bg-gray-800 shadow rounded w-full">
            <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
            {sinduList && (sinduList.length!= 0) &&
                <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
                    <span className="text-gray-500">{todayDate.toString().slice(0,16) }</span>
                </p>
            }
                {/* <div className="relative">
                    <div onclick={() => setShowDropdown(!showDropdown)} className="relative z-0 bg-gray-100 dark:bg-gray-700 flex items-center justify-between w-full cursor-pointer text-xs form-select text-gray-600 dark:text-gray-400 md:p-3 p-2 rounded bg-transparent" data-menu>
                        <p className="leading-3 tracking-normal font-normal text-sm">
                            Show:
                            <span className="text-indigo-700 dark:text-indigo-600 mr-2">This week</span>
                        </p>
                        <div className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" className="hidden icon icon-tabler icon-tabler-chevron-up" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <polyline points="6 15 12 9 18 15" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-up" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </div>
                    </div>
                    {showDropdown && (
                        <ul className="invisible z-10 transition duration-300 opacity-0 bg-white dark:bg-gray-700 shadow rounded mt-2 w-40 py-1 absolute">
                            <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal">Previous week</li>
                            <li className="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal">Next week</li>
                        </ul>
                    )}
                </div> */}
            </div>
            {/* <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-4 bg-white dark:bg-gray-800">
                <div className="xl:flex lg:flex md:flex sm:flex">
                    <div className="xl:w-1/4 w-full mb-2 xl:mb-0">
                        <p className="text-sm text-gray-600 dark:text-gray-400 font-bold">Tasks Scheduled: 24</p>
                    </div>
                    <div className="xl:w-1/4 w-full mb-2 xl:mb-0">
                        <p className="text-sm text-gray-600 dark:text-gray-400 font-bold">Tasks Pending: 15</p>
                    </div>
                    <div className="xl:w-1/4 w-full mb-2 xl:mb-0">
                        <p className="text-sm text-gray-600 dark:text-gray-400 font-bold">Tasks Completed: 09</p>
                    </div>
                </div>
            </div> */}
            
            <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
            {sinduList && sinduList.map((sindu) => (
                <div className="p-5 pl-10 m-4 flex justify-between rounded mb-6 bg-gradient-to-r from-cyan-50 to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900">
                    <div className="w-3/5">
                        <p className="mb-2 text-sm font-bold text-gray-800 dark:text-gray-100">{sindu.chartpos} - {sindu.album}</p>
                        <ul>
                            <li className="mb-1 text-xs text-gray-600 dark:text-gray-400">Artist - {sindu.artist}</li>
                            <li className="mb-1 text-xs text-gray-600 dark:text-gray-400">Title - {sindu.title}</li>
                            <li className="text-xs text-gray-600 dark:text-gray-400">Language - {sindu.language}</li>
                            <li>
                                <div className="flex items-center pt-2 pb-2.5">
                                    <div className="w-6 h-6 mr-1 shadow rounded-full">
                                        {/* <img alt="img-1" src={"https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"} className="w-full h-full object-cover object-center rounded-full" /> */}
                                        <FcMusic />
                                    </div>
                                    <div className="w-6 h-6 mr-1 shadow rounded-full">
                                        {/* <img alt="img-1" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80" className="w-full h-full object-cover object-center rounded-full" /> */}
                                        <FcCustomerSupport/>
                                    </div>
                                    <div className="w-6 h-6 mr-1 shadow rounded-full">
                                        {/* <img alt="img-1" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" className="w-full h-full object-cover object-center rounded-full" /> */}
                                        <FcPhotoReel/>
                                    </div>
                                    <div className="w-6 h-6 mr-1 shadow rounded-full">
                                        {/* <img alt="img-1" src="https://images.unsplash.com/photo-1513956589380-bad6acb9b9d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" className="w-full h-full object-cover object-center rounded-full" /> */}
                                        <FcPositiveDynamic />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="w-3/12 flex flex-col items-end justify-between">
                        <p className="text-xs text-gray-600 dark:text-gray-400">Date - {sindu.date_stamp_language} </p>
                        <div className="bg-gradient-to-r from-white to-cyan-100  dark:bg-gradient-to-r dark:from-slate-900 dark:to-cyan-900 h-6 w-20 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                            <span className="text-xs text-slate-500 font-extrabold dark:text-gray-300 font-normal">Count - {sindu.song_count}</span>
                        </div>
                    </div>
                </div>
            ))}

            </div>
        </div>
     
        )
      }

    
  return (


    <div class="container m-auto ">
    <div class="py-8">
        <div>
            <h2 class="text-xl font-semibold leading-tight dark:text-white">Tamil Daily Top 20 Songs</h2>
        </div>

        <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-end xs:justify-between   dark:bg-gray-900 dark:text-zinc-500 dark:border-zinc-900  ">
                   
                    <div class="inline-flex mt-2 xs:mt-0 ">
                        <button
                            class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l  dark:bg-slate-700 dark:text-zinc-500 dark:hover:bg-slate-900 "
                            onClick={()=> {
                                setTableView(true)
                                setGridView(false)
                                setListView(false)
                             }}
                            >
                            <BsTable />
                        </button>
                        <button
                            class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-1 dark:bg-slate-700 dark:text-zinc-500 dark:border-zinc-900 dark:hover:bg-slate-900 "
                            onClick={()=> {
                                setTableView(false)
                                setGridView(true)
                                setListView(false)
                             }}
                            >
                            <BsGrid3X3GapFill />
                        </button>
                        <button
                            class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r dark:bg-slate-700 dark:text-zinc-500 dark:border-zinc-900 dark:hover:bg-slate-900 " 
                            onClick={()=> {
                                setTableView(false)
                                setGridView(false)
                                setListView(true)
                             }}
                            >
                            <FaThList />
                        </button>
                    </div>
               
                </div>

       
            {
                (tableView== true) ?<TableView/>: (tableView==false && listView==false && gridView== true)? <GridView/> : <ListView/>
            }
        
        



    </div>
</div>

  )
}

export default Top20TamilDailyChart;


 
