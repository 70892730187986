import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../services/Account";
import Toggle from "./theme/ThemeToggle";
import nameTag from "../assets/logo/SinduChart.png";
import lightLogo from "../assets/logo/lighttheme.png";
// import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
// import { CognitoUserPool } from "amazon-cognito-identity-js";
import store from "../app/store";
import { artistArraySuccess } from "../reducers/reportSlice";
const Swal = require("sweetalert2");
var AWS = require("aws-sdk");

Amplify.configure({
  Auth: {
    userPoolId: "eu-central-1_KbFYRIGQX", //UserPool ID
    region: "eu-central-1",
    userPoolWebClientId: "4p36vhmntiie1r8734scsmh2u6", //WebClientId
  },
});

const TopNavbar = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState(nameTag);

  const [mobile, setMobile] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [artistArray, setArtistArray] = useState([]);
  console.log("artist list form signin", artistArray);
  const { logout } = useContext(AccountContext);

  useEffect(() => {
    const userMedia = window.matchMedia("prefers-color-scheme:dark");
    console.log(userMedia);
  }, []);

  console.log("page reloaded");

  function refreshPage() {
    window.location.reload(false);
  }

  const onSubmit = (e) => {
    console.log("cognito called");
    // e.preventDefault();
    const attributeList = [
      { Name: "given_name", Value: firstName },
      {
        Name: "family_name",
        Value: lastName,
      },
      {
        Name: "email",
        Value: email,
      },
      {
        Name: "birthdate",
        Value: birthdate,
      },
      {
        Name: "phone_number",
        Value: mobile,
      },
    ];

    UserPool.signUp(username, password, attributeList, null, (err, data) => {
      if (err) {
        console.log(err);
        // alert("Couldn't sign up");
        Swal.fire({
          title: "Couldnt sign up",
          text: err.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        console.log(data);
        // alert("User Added Successfully");
        Swal.fire({
          title: "Success!",
          text: "User Added Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });

        setMobile();
        setFirstName("");
        setLastName("");
        setUsername("");
        setBirthDate("");
        setEmail("");
      }
    });
  };

  const onSignInSubmit = async () => {
    Auth.signIn(username, password)
      .then((result) => {
        console.log(result.attributes.given_name);
        console.log(result);
        console.log(result.attributes["custom:artists"]);
        setArtistArray(result.attributes["custom:artists"]);
        localStorage.setItem(
          "artistArray",
          result.attributes["custom:artists"]
        );
        store.dispatch(artistArraySuccess(result.attributes["custom:artists"]));

        Swal.fire({
          title: `Hi ${result.attributes.given_name} ${result.attributes.family_name}!`,
          text: " Welcome to Sindu Chart!!",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/reports");
            refreshPage();
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Cannot sign In",
          text: err.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const signOut = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
        Swal.fire("success");
        refreshPage();
      }
    });
  };

  const [showModal, setShowModal] = useState(false);
  const ShowModalOpenHandleOpen = () => {
    setShowModal(true);
  };
  const ShowModalOpenHandleClose = () => {
    setShowModal(false);
  };

  console.log(showModal);

  const openModal = () => {
    console.log("function called");
    return (
      <div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black "></div>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl w-96 mt-36 ">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <div class="bg-grey-lighter flex flex-col">
                <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                  <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
                    <button
                      type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      data-modal-toggle="popup-modal"
                      onClick={() => {
                        ShowModalOpenHandleClose();
                        // setShowModal(false)
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                    <h1 class="mb-8 text-3xl text-center">Sign up</h1>
                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="firstname"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFirstName(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="lastname"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="email"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <label for="floatingInput" class="text-gray-700">
                      Select a date
                    </label> */}
                    <input
                      type="date"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="birthdate"
                      value={birthdate}
                      placeholder="Birthdate"
                      onChange={(e) => setBirthDate(e.target.value)}
                    />

                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="mobile"
                      placeholder="Mobile Number"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="username"
                      value={username}
                      placeholder="User Name"
                      onChange={(e) => setUsername(e.target.value)}
                    />

                    <input
                      type="password"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* <input
                          type="password"
                          class="block border border-grey-light w-full p-3 rounded mb-4"
                          name="confirm_password"
                          placeholder="Confirm Password"
                        /> */}
                    <div className="flex  justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="bg-emerald-500 w-full text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          onSubmit();
                          // setShowModal(false);
                          ShowModalOpenHandleClose();
                        }}
                      >
                        Sign Up
                      </button>
                    </div>

                    <p class="mb-4 text-center">OR</p>
                    <hr class="block w-full mb-4 border-0 border-t border-gray-300" />

                    <div class="flex flex-wrap justify-center">
                      <div class="w-full sm:w-1/2 sm:pr-2 mb-3 sm:mb-0">
                        <button
                          class="w-full bg-cyan-600 hover:bg-blue-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                          type="button"
                        >
                          Facebook
                        </button>
                      </div>
                      <div class="w-full sm:w-1/2 sm:pl-2">
                        <button
                          class="w-full bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                          type="button"
                        >
                          Google
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div class="text-grey-dark mt-6">
                        Already have an account?
                        <a
                          class="no-underline border-b border-blue text-blue"
                          href="../login/"
                        >
                          Log in
                        </a>
                        .
                      </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    );
  };

  const [showSignInModal, setShowSignInModal] = useState(false);
  const ShowSignInModalOpenHandleOpen = () => {
    setShowSignInModal(true);
  };
  const ShowSignInModalOpenHandleClose = () => {
    setShowSignInModal(false);
  };

  const openSignInModal = () => {
    console.log("function called");
    return (
      <div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black "></div>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl w-96 mt-0 ">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <div class="bg-grey-lighter flex flex-col">
                <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                  <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
                    <button
                      type="button"
                      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      data-modal-toggle="popup-modal"
                      onClick={() => {
                        ShowSignInModalOpenHandleClose();
                        // setShowModal(false)
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                    <h1 class="mb-8 text-3xl text-center">Sign In</h1>

                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="username"
                      value={username}
                      placeholder="User Name"
                      onChange={(e) => setUsername(e.target.value)}
                    />

                    <input
                      type="password"
                      class="block border border-grey-light w-full p-3 rounded mb-4"
                      name="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="flex  justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="bg-emerald-500 w-full text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          onSignInSubmit();

                          ShowSignInModalOpenHandleClose();
                        }}
                      >
                        Sign In
                      </button>
                    </div>

                    <p class="mb-4 text-center">OR</p>
                    <hr class="block w-full mb-4 border-0 border-t border-gray-300" />

                    <div class="flex flex-wrap justify-center">
                      <div class="w-full sm:w-1/2 sm:pr-2 mb-3 sm:mb-0">
                        <button
                          class="w-full bg-cyan-600 hover:bg-blue-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                          type="button"
                        >
                          Facebook
                        </button>
                      </div>
                      <div class="w-full sm:w-1/2 sm:pl-2">
                        <button
                          class="w-full bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                          type="button"
                        >
                          Google
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div class="text-grey-dark mt-6">
                        Already have an account?
                        <a
                          class="no-underline border-b border-blue text-blue"
                          href="../login/"
                        >
                          Log in
                        </a>
                        .
                      </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    );
  };

  return (
    <>
      {showModal ? openModal() : null}
      {showSignInModal ? openSignInModal() : null}
      <header className="p-4  bg-slate-50  md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-900 mb-0 pb-0">
        <div className="mb-6 md:mb-0">
          <a href="/" className="flex items-center">
            <img src={lightLogo} className="mr-3 h-12 " alt="FlowBite Logo" />
          </a>
        </div>

        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <button
              onClick={() => {
                // setShowModal(true)
                ShowModalOpenHandleOpen();
                // openModal();
              }}
              class="block text-white bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              data-modal-toggle="authentication-modal"
            >
              Sign Up
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                // setShowModal(true)
                ShowSignInModalOpenHandleOpen();
                // openModal();
              }}
              class="block text-white bg-green-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              data-modal-toggle="authentication-modal"
            >
              Sign In
            </button>
          </li>
          <li>
            <button
              onClick={signOut}
              class="block text-white bg-red-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              data-modal-toggle="authentication-modal"
            >
              Logout
            </button>
          </li>
          <li>
            <Toggle />
          </li>
          <li className="transition ease-in-out duration-500 rounded-full p-2 text-gray-500 text-2xl dark:text-gray-400 cursor-pointer"></li>

          <li className="transition ease-in-out duration-500 rounded-full p-2 text-gray-500 text-2xl dark:text-gray-400 cursor-pointer"></li>
        </ul>
      </header>
    </>
  );
};

export default TopNavbar;
// export default React.memo(TopNavbar);
