import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

//const baseUrl = "http://localhost:3050/";
 const baseUrl = "https://www.s1.sinduchart.com/"; //"http://192.168.1.21:3050";

export const sinduApi = createApi({
  reducerPath: "sinduApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getsinhalaDailyChart: builder.query({
      query: () => {
        return {
          url: `chart/top-sinhale-daily-chart`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTamilDailyChart: builder.query({
      query: () => {
        return {
          url: `chart/top-tamil-daily-chart`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getSinhalaDailyChartHistory: builder.query({
      query: ({ filterDate, listLimit }) => {
        // console.log(filterDate,listLimit)
        return {
          url: `chart/sinhala_daily-charthistory?limit=${listLimit}&date=${filterDate}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTamilDailyChartHistory: builder.query({
      query: ({ filterDate, listLimit }) => {
        return {
          url: `chart/tamil_daily-charthistory?date=${filterDate}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTopAlbums: builder.query({
      query: ({ filterMonth, filterYear, listLimit }) => {
        // console.log(filterMonth,filterYear)
        return {
          url: `album/top-albums?year=${filterYear}&month=${filterMonth}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTopArtists: builder.query({
      query: ({ filterMonth, filterYear, listLimit }) => {
        return {
          url: `artist/top-artist?year=${filterYear}&month=${filterMonth}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTopSongsInChannels: builder.query({
      query: ({
        listLimit,
        filterDay,
        filtermonth,
        filterYear,
        radioStation,
      }) => {
        return {
          url: `chart/top-in_station?year=${filterYear}&month=${filtermonth}&date=${filterDay}&limit=${listLimit}&station=${radioStation}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTopAlbumsByLanguage: builder.query({
      query: ({ filterMonth, filterYear, listLimit, listLanguage }) => {
        // console.log(listLanguage)
        return {
          url: `album/top-albums-by-lang?year=${filterYear}&month=${filterMonth}&language=${listLanguage}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTopArtistsByLanguage: builder.query({
      query: ({ filterMonth, filterYear, listLimit, listLanguage }) => {
        // console.log(listLanguage)
        return {
          url: `artist/top-artist-by-lang?year=${filterYear}&month=${filterMonth}&language=${listLanguage}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),

    // dynamo endpooints
    getTop20SinhalaTodaySongsList: builder.query({
      query: ({ filterMonth, filterYear, filterDay }) => {
        // console.log(listLanguage)
        return {
          url: `chart/songs_chart?year=${filterYear}&month=${filterMonth}&date=${filterDay}&limit=20&language=Sinhala`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTop20TamilTodaySongsList: builder.query({
      query: ({ filterMonth, filterYear, filterDay }) => {
        // console.log(listLanguage)
        return {
          url: `chart/songs_chart?year=${filterYear}&month=${filterMonth}&date=${filterDay}&limit=20&language=Tamil`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getSinhalaAllChartsList: builder.query({
      query: ({ filterMonth, filterYear, filterDay, listLimit }) => {
        // console.log(listLanguage)
        return {
          url: `chart/songs_chart?year=${filterYear}&month=${filterMonth}&date=${filterDay}&limit=${listLimit}&language=Sinhala`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getTamilAllChartsList: builder.query({
      query: ({ filterMonth, filterYear, filterDay, listLimit }) => {
        // console.log(listLanguage)
        return {
          url: `chart/songs_chart?year=${filterYear}&month=${filterMonth}&date=${filterDay}&limit=${listLimit}&language=Tamil`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getAllAlbumsList: builder.query({
      query: ({ filterMonth, filterYear, listLimit, listLanguage }) => {
        // console.log(listLanguage)
        return {
          url: `/album/album_chart?year=${filterYear}&month=${filterMonth}&language=${listLanguage}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getAllArtistsList: builder.query({
      query: ({ filterMonth, filterYear, listLimit, listLanguage }) => {
        // console.log(listLanguage)
        return {
          url: `/artist/artist_chart?year=${filterYear}&month=${filterMonth}&language=${listLanguage}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getAllFMChannelChartList: builder.query({
      query: ({
        filtermonth,
        filterYear,
        listLimit,
        radioStation,
        filterDay,
      }) => {
        // console.log(listLanguage)
        return {
          url: `/fmchannel/fm_songs_chart?year=${filterYear}&month=${filtermonth}&date=${filterDay}&station=${radioStation}&limit=${listLimit}`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
    getReportData: builder.query({
      query: ({ filterArtist, filtermonth, filterYear, radioStation }) => {
        return {
          url: `/artist/artist_report?year=${filterYear}&month=${filtermonth}&fm=${radioStation}&artist_name=${filterArtist}&artist_report=true`,
          method: "get",
          headers: {
            Accept: "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetsinhalaDailyChartQuery,
  useLazyGetTamilDailyChartQuery,
  useLazyGetSinhalaDailyChartHistoryQuery,
  useLazyGetTamilDailyChartHistoryQuery,
  useLazyGetTopAlbumsQuery,
  useLazyGetTopArtistsQuery,
  useLazyGetTopSongsInChannelsQuery,
  useLazyGetTopAlbumsByLanguageQuery,
  useLazyGetTopArtistsByLanguageQuery,
  //   DynamoEndpoints
  useLazyGetTop20SinhalaTodaySongsListQuery,
  useLazyGetTop20TamilTodaySongsListQuery,
  useLazyGetSinhalaAllChartsListQuery,
  useLazyGetTamilAllChartsListQuery,
  useLazyGetAllAlbumsListQuery,
  useLazyGetAllArtistsListQuery,
  useLazyGetAllFMChannelChartListQuery,
  useLazyGetReportDataQuery,
  useGetReportDataQuery,
} = sinduApi;
