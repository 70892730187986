import { configureStore } from "@reduxjs/toolkit";
import { sinduApi } from "../services/sinduApi";
import reportSlice from "../reducers/reportSlice";


export default configureStore({
    reducer: {
      report:reportSlice,
      [sinduApi.reducerPath]: sinduApi.reducer,
     
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
  });