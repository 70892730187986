import React, { useEffect, useContext, useState } from "react";
import { render } from "react-dom";
import { AccountContext } from "../services/Account";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Home from "../pages/Home";
import News from "../pages/News";
import SinhalaDailyChartHistory from "../pages/SinhalaDailyChartHistory";
import TamilDailyChartHistory from "../pages/TamilDailyChartHistory";
import Top20SinhalaDailyChart from "../pages/Top20SinhalaDailyChart";
import Top20TamilDailyChart from "../pages/Top20TamilDailyChart";
import TopAlbums from "../pages/TopAlbums";
import TopArtists from "../pages/TopArtists";
import TopSongsInChannels from "../pages/TopSongsInChannels";
import AdBannerMain from "./AdBannerMain";
import NewsLine from "./NewsLine";
import TrendingList from "./TrendingList";
import ReactGA from "react-ga";
import Reports from "../pages/Reports";

const TRACKING_ID = "G-JG7EY7RPNT"; //G-PGFJ4FGLGJ UA-247955899-1
ReactGA.initialize(TRACKING_ID);

const MainContent = () => {
  const [authStatus, setAuthStatus] = useState(false);
  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    getSession().then((session) => {
      console.log("Session:", session);
      setAuthStatus(true);
    });
  }, []);

  return (
    <div className=" flex-wrap min-h-screen ">
      <Routes>
        {/* <Route path="/" element={<Top20SinhalaDailyChart/>}/> */}
        <Route path="/" element={<SinhalaDailyChartHistory />} />
        <Route path="/tamil-songs-chart" element={<TamilDailyChartHistory />} />
        {/* <Route path="/top20-tamil-daily-chart" element={<Top20TamilDailyChart/>}/> */}
        <Route path="/top-albums" element={<TopAlbums />} />
        <Route path="/top-artists" element={<TopArtists />} />
        <Route path="/news" element={<News />} />
        <Route path="/top-songs-in-channels" element={<TopSongsInChannels />} />
        {authStatus ? (
          <Route path="/reports" element={<Reports />} />
        ) : (
          <Route path="/reports" element={<SinhalaDailyChartHistory />} />
        )}
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route index element={<Home />} />
              <Route path="teams" element={<Teams />}>
                <Route path=":teamId" element={<Team />} />
                <Route path="new" element={<NewTeamForm />} />
                <Route index element={<LeagueStandings />} /> */}
        {/* </Route> */}
        {/* </Route> */}
      </Routes>

      <AdBannerMain />
      {/* <TrendingList/> */}
      <NewsLine />
    </div>
  );
};

export default MainContent;
